import React, {
  useEffect,
  useState,
  useContext,
  useLayoutEffect,
  useRef,
  useCallback,
} from "react";
import axios from "axios";
import { getSymbols } from "../utils/api";
import { useLtpContext } from "../context/LtpContext";
import { useBasketContext } from "../context/BasketContext";
import ABWebSocketContext from "../context/ABWebsocketContext";
import CustomToastContent from "./CustomToastContent";
import { toast } from "react-toastify";
import SuccessIcon from "../images/success_icon.svg";

const OptionChainTableMobile = ({
  parentCallback2,
  optionData,
  showCalls,
  showPuts,
  symbol,
  calls,
  puts,
  clickEnable,
  showDelta,
  showTheta,
  showGamma,
  showVega,
  showOIChng,
  showOIBuilddup,
  isScalpOrderChecked,
}) => {
  const { symbolData } = useContext(ABWebSocketContext);
  const [displayCalls, setDisplayCalls] = useState(showCalls);
  const [displayPuts, setDisplayPuts] = useState(showPuts);
  //taking from modal table
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [activeDate, setActiveDate] = useState("");
  const [reducedData, setReducedData] = useState();

  const [scrollX, setScrollX] = useState(0);

  const [atmIndex, setAtmindex] = useState(0);
  const [ltp, setLtp] = useState(0);
  // const { ltpValue } = useLtpContext();
  const { addToBasket } = useBasketContext();
  const ltpValue = symbolData[selectedSymbol?.ab_code]?.ltp || 0;
  var iv = require("implied-volatility");
  var jStat = require("jstat");
  var greeks = require("greeks");

  // Update the state variables when props change
  useEffect(() => {
    setDisplayCalls(showCalls);
    setDisplayPuts(showPuts);
  }, [showCalls, showPuts]);

  const getRowColor = (strike, isCall) => {
    if ((isCall && strike < ltpValue) || (!isCall && strike > ltpValue)) {
      return isCall ? "#FFF9E5" : "#F4F5FF"; // Light yellow for ITM call options, Light blue for ITM put options
    } else {
      return "white"; // White for OTM and ATM options
    }
  };

  const returnAtmIndex = () => {
    let index = 0;
    const ltp = symbolData[symbol?.ab_code]?.ltp || 0;
    if (optionData.options && optionData.options) {
      let curr = optionData.options[0].strike,
        diff = Math.abs(ltp - curr);
      for (let val = 0; val < optionData.options.length; val++) {
        let newdiff = Math.abs(ltp - optionData.options[val].strike);
        if (newdiff < diff) {
          diff = newdiff;
          curr = optionData.options[val];
          index = val;
        }
      }
    }

    setAtmindex(index);
  };

  useEffect(() => {
    returnAtmIndex();
  }, [calls, symbol]);

  const getPutRowColor = (strike) => {
    if (strike > ltpValue) {
      return "#F4F5FF";
    } else {
      return "white";
    }
  };

  useEffect(() => {
    const storedSymbol = sessionStorage.getItem("selectedSymbol");
    if (storedSymbol) {
      setSelectedSymbol(JSON.parse(storedSymbol));
    } else {
      const fetchSymbols = async () => {
        const symbols = await getSymbols();
        if (symbols) {
          const niftySymbol = symbols.find(
            (symbol) => symbol.scrip === "NIFTY"
          );
          setSelectedSymbol(niftySymbol);
          sessionStorage.setItem("selectedSymbol", JSON.stringify(niftySymbol));
        }
      };

      fetchSymbols();
    }
  }, [symbol]);

  useEffect(() => {
    returnAtmIndex();
  }, [calls, symbol]);

  const fetchDataForSymbol = async (symbol, date) => {
    const apiEndpoint = `https://yvqy9ihwn6.execute-api.ap-south-1.amazonaws.com/default/optiongeeks_fyers_expirysymbols?id=${symbol}${date}`;
    try {
      const response = await axios.get(apiEndpoint);
      const data = response.data;
      const f = getReducedData();
      setReducedData(f);
      console.log("Received data is: ", data);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    if (selectedSymbol) {
      const formattedDate = selectedDate.replaceAll(" ", "%20");
      fetchDataForSymbol(selectedSymbol.scrip, formattedDate);
    }
  }, [selectedSymbol, selectedDate]);

  // useEffect(() => {
  //   // Fetch data for the selected symbol and date when either of them changes
  //   if (selectedSymbol && selectedDate) {
  //     const formattedDate = selectedDate.replaceAll(" ", "%20");
  //     fetchDataForSymbol(selectedSymbol.scrip, formattedDate);
  //   }
  // }, [selectedSymbol, selectedDate]);

  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedSide, setSelectedSide] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState({
    top: 0,
    left: 0,
  });
  const getReducedData = () => {
    const sample = optionData && optionData.options && optionData.options;
    if (sample) {
      let f = sample.reduce((groupedOptions, option) => {
        // console.log("Processing option:", option);
        const strike = option.call.strike;
        //console.log("Strike:", strike);

        if (!groupedOptions[strike]) {
          // console.log("Creating new entry for strike:", strike);
          groupedOptions[strike] = {
            call: option.call,
            put: option.put || {}, // Handle missing put data
          };
        } else {
          //console.log("Adding to existing entry for strike:", strike);
          groupedOptions[strike].call = {
            ...groupedOptions[strike].call,
            ...option.call,
          };
          groupedOptions[strike].put = {
            ...groupedOptions[strike].put,
            ...option.put,
          };
        }
        return groupedOptions;
      }, {});
      // console.log("Final groupedOptions:", f);
      return f;
    }
  };
  const [divVisibility, setDivVisibility] = useState(false);

  const [selectedRowLtp, setSelectedRowLtp] = useState(0);
  const handleRowClick = (event, rowIndex, side) => {
    setDivVisibility(true);
    if (clickEnable) {
      const rowRect = event.currentTarget.getBoundingClientRect();
      const top = rowRect.top + window.scrollY;
      const left = rowRect.left + window.scrollX;
      setSelectedSide(side);
      setSelectedRowIndex(rowIndex);
      setSelectedPosition({ top, left });

      // // Toggling visibility of clicked row's div
      // const newVisibility = [...divVisibility];
      // newVisibility[rowIndex] = !newVisibility[rowIndex];

      setSelectedRowLtp(
        side === "calls"
          ? symbolData[calls[rowIndex]?.ab_code]?.ltp || 0
          : symbolData[puts[rowIndex]?.ab_code]?.ltp || 0
      );
    }
  };

  const containerRef = useRef(null);
  const selectedRowIndexRef = useRef(null);
  const selectedSideRef = useRef(null);
  const optionDataRef = useRef(null);

  const handleButtonClick = (event) => {
    const target = event.target;
    const isBuyButton = target.classList.contains("buy-btn");
    const isSellButton = target.classList.contains("sell-btn");

    //these are workig fine
    console.log("Clicked Element:", target);
    console.log("Buy button yes or no", isBuyButton);
    console.log("sell button yes or no?", isSellButton);

    if (isBuyButton) {
      setSelectedRowIndex(selectedRowIndexRef.current);
      setSelectedSide(selectedSideRef.current);
      handleBuyClick();
    } else if (isSellButton) {
      setSelectedRowIndex(selectedRowIndexRef.current);
      setSelectedSide(selectedSideRef.current);
      handleSellClick();
    }
  };

  //This block of container is used because in a way we have two onclick elements at a single place
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("click", handleButtonClick);
    }

    return () => {
      if (container) {
        container.removeEventListener("click", handleButtonClick);
      }
    };
  }, []);

  useEffect(() => {
    selectedRowIndexRef.current = selectedRowIndex;
    selectedSideRef.current = selectedSide;
  }, [selectedRowIndex, selectedSide]);

  let uniqueStrikes = Array.from(
    new Set(
      optionData.options
        ? optionData.options.map((option) => option.call.strike)
        : null
    )
  );
  uniqueStrikes.sort((a, b) => a - b);
  if (uniqueStrikes.length == 0) {
    setTimeout(() => {
      uniqueStrikes = Array.from(
        new Set(
          optionData.options
            ? optionData.options.map((option) => option.call.strike)
            : null
        )
      );
    }, 1000);
  }
  if (calls) {
    calls.sort((a, b) => a.strike - b.strike);
  }
  if (puts) {
    puts.sort((a, b) => a.strike - b.strike);
  }

  // const [isDivVisible, setDivVisible] = useState(true);
  const [lotSizeInput, setLotSizeInput] = useState(1);

  const handleBuyClick = useCallback(() => {
    console.log("Buy button clicked");
    console.log("selectedSymbol in handleBuyClick", selectedSymbol);
    console.log("reduced data in handleBuyClick", reducedData);
    if (isScalpOrderChecked) {
      toast.success(
        <CustomToastContent
          heading="Order placed successfully!"
          message="Your Scalp Order has been successfully placed with the broker."
        />,
        {
          className: "custom-success-toast",
          icon: <img src={SuccessIcon} alt="Success Icon" />,
        }
      );
      return;
    }
    const f = getReducedData();
    // setDivVisible(false);

    if (
      selectedSymbol &&
      reducedData &&
      selectedRowIndex !== null &&
      selectedSide !== null
    ) {
      // const selectedOption = f[selectedRowIndex];
      const selectedStrike = uniqueStrikes[selectedRowIndex]; // Get the selected strike value
      const selectedOption = f[selectedStrike]; // Use the strike value as the key

      console.log("The selected option in handleBuyClick is:", selectedOption);
      const lotSizeForOrder = parseInt(lotSizeInput); // Use the input value
      const side = selectedSide === "calls" ? "Call" : "Put";
      const instrumentName =
        side === "Call"
          ? selectedOption.call.instrument_name
          : selectedOption.put.instrument_name;

      //console.log("instrumentName in buy click:", instrumentName);
      const price =
        selectedSide === "calls"
          ? symbolData[calls[selectedRowIndex]?.ab_code]?.ltp || 0
          : symbolData[puts[selectedRowIndex]?.ab_code]?.ltp || 0;
      const lotSize =
        side === "Call"
          ? selectedOption.call.lot_size
          : selectedOption.put.lot_size;
      const optionType = side === "Call" ? "CE" : "PE";
      const tradingSymbol =
        side === "Call"
          ? selectedOption.call.trading_symbol
          : selectedOption.put.trading_symbol;
      const expiryDate =
        side === "Call"
          ? selectedOption.call.expiry_date
          : selectedOption.put.expiry_date;
      const code =
        side === "Call" ? selectedOption.call.code : selectedOption.put.code;
      const ab_code =
        side === "Call"
          ? selectedOption.call.ab_code
          : selectedOption.put.ab_code;
      const buy_sell = "B";
      const underlying_abcode = selectedSymbol.ab_code;

      const quantity =
        side === "Call"
          ? selectedOption.call.lot_size
          : selectedOption.put.lot_size;

      const strike =
        side === "Call"
          ? selectedOption.call.strike
          : selectedOption.put.strike;
      const dataObject = {
        side,
        instrumentName,
        lotSize: lotSizeForOrder,
        optionType,
        tradingSymbol,
        expiryDate,
        code,
        ab_code,
        price,
        buy_sell,
        underlying_abcode,
        strike,
        quantity,
      };
      parentCallback2([dataObject, "B"]);
      console.log("Latest Updated Data Object (BUY):", dataObject);
      addToBasket(dataObject);
      setDivVisibility(false);
    }
    if (selectedSide === "calls") {
      const updatedTags = [...buySellTagsCalls];
      updatedTags[selectedRowIndex] = "buy";
      setBuySellTagsCalls(updatedTags);
    } else if (selectedSide === "puts") {
      const updatedTags = [...buySellTagsPuts];
      updatedTags[selectedRowIndex] = "buy";
      setBuySellTagsPuts(updatedTags);
    }
  }, [
    selectedRowIndex,
    selectedSide,
    optionData,
    uniqueStrikes,
    reducedData,
    lotSizeInput,
  ]);

  const handleSellClick = useCallback(() => {
    // setDivVisible(false);
    if (isScalpOrderChecked) {
      toast.success(
        <CustomToastContent
          heading="Order placed successfully!"
          message="Your Scalp Order has been successfully placed with the broker."
        />,
        {
          className: "custom-success-toast",
          icon: <img src={SuccessIcon} alt="Success Icon" />,
        }
      );

      return;
    }

    const f = getReducedData();

    if (
      selectedSymbol &&
      reducedData &&
      selectedRowIndex !== null &&
      selectedSide !== null
    ) {
      console.log("Selected Row Indexxxx:", selectedRowIndex);
      const selectedStrike = uniqueStrikes[selectedRowIndex]; // Get the selected strike value
      const selectedOption = f[selectedStrike]; // Use the strike value as the key
      //  console.log("Selected Option in handleSellClick:", selectedOption);

      const lotSizeForOrder = parseInt(lotSizeInput);
      const side = selectedSide === "calls" ? "Call" : "Put";
      const price =
        selectedSide === "calls"
          ? symbolData[calls[selectedRowIndex]?.ab_code]?.ltp || 0
          : symbolData[puts[selectedRowIndex]?.ab_code]?.ltp || 0;
      const instrumentName =
        side === "Call"
          ? selectedOption.call.instrument_name
          : selectedOption.put.instrument_name;
      const lotSize =
        side === "Call"
          ? selectedOption.call.lot_size
          : selectedOption.put.lot_size;
      const optionType = side === "Call" ? "CE" : "PE";
      const tradingSymbol =
        side === "Call"
          ? selectedOption.call.trading_symbol
          : selectedOption.put.trading_symbol;
      const expiryDate =
        side === "Call"
          ? selectedOption.call.expiry_date
          : selectedOption.put.expiry_date;

      const quantity =
        side === "Call"
          ? selectedOption.call.lot_size
          : selectedOption.put.lot_size;

      const strike =
        side === "Call"
          ? selectedOption.call.strike
          : selectedOption.put.strike;
      const code =
        side === "Call" ? selectedOption.call.code : selectedOption.put.code;
      const ab_code =
        side === "Call"
          ? selectedOption.call.ab_code
          : selectedOption.put.ab_code;
      const buy_sell = "S";
      const underlying_abcode = selectedSymbol.ab_code;
      const dataObject = {
        side,
        instrumentName,
        lotSize: lotSizeForOrder,
        optionType,
        tradingSymbol,
        expiryDate,
        code,
        ab_code,
        price,
        buy_sell,
        underlying_abcode,
        strike,
        quantity,
      };

      parentCallback2([dataObject, "S"]);
      console.log("Latest Updated Data Object (SELL):", dataObject);
      addToBasket(dataObject);
      setDivVisibility(false);
    }
    if (selectedSide === "calls") {
      const updatedTags = [...buySellTagsCalls];
      updatedTags[selectedRowIndex] = "sell";
      setBuySellTagsCalls(updatedTags);
    } else if (selectedSide === "puts") {
      const updatedTags = [...buySellTagsPuts];
      updatedTags[selectedRowIndex] = "sell";
      setBuySellTagsPuts(updatedTags);
    }
  }, [selectedRowIndex, selectedSide, optionData, uniqueStrikes, lotSizeInput]);

  useEffect(() => {
    if (selectedSymbol) {
      const symbolCode = selectedSymbol.ab_code;
      const ltpValue = symbolData[symbolCode]?.ltp || 0;
      setLtp(ltpValue);
    }
  }, [selectedSymbol, symbolData]);

  useLayoutEffect(() => {
    const computeGreeks = () => {
      const spot = parseFloat(symbolData[selectedSymbol?.ab_code]?.ltp);
      const riskFreeRate = 0.1 / 365;

      calls?.forEach((call_option, i) => {
        const strike = parseFloat(call_option?.strike);
        const targetDateTimeString = call_option.expiry_date + " 15:29:59";
        const targetDateTime = new Date(targetDateTimeString);
        const currentDate = new Date();
        let timeToExpiration = Math.max(
          0,
          (targetDateTime - currentDate) / (24 * 60 * 60 * 365 * 1000) ||
            0.00002 / 365
        );

        if (
          strike !== 0 &&
          timeToExpiration > 0 &&
          symbol?.scrip === selectedSymbol?.scrip
        ) {
          const isCallOption = strike > spot;
          const optionType = isCallOption ? "call" : "put";
          const relevantOption = isCallOption ? call_option : puts[i];
          let initialGuessIv;
          if (symbolData[relevantOption?.ab_code] && !relevantOption.iv) {
            const currentStockPrice = parseFloat(
              symbolData[relevantOption?.ab_code]?.ltp
            );

            initialGuessIv = iv.getImpliedVolatility(
              currentStockPrice,
              spot,
              strike,
              timeToExpiration,
              riskFreeRate,
              optionType
            );
          }
          if (initialGuessIv <= 1) {
            call_option.iv = (initialGuessIv * 100).toFixed(2);
            call_option.delta = (
              greeks.getDelta(
                spot,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "call"
              ) * 100
            ).toFixed(2);
            call_option.theta = (
              greeks.getTheta(
                spot,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "call"
              ) * 100
            ).toFixed(2);
            call_option.gamma = (
              greeks.getGamma(
                spot,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "call"
              ) * 100
            ).toFixed(2);
            call_option.vega = (
              greeks.getVega(
                spot,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "call"
              ) * 100
            ).toFixed(2);

            puts[i].iv = (initialGuessIv * 100).toFixed(2);
            puts[i].delta = (
              greeks.getDelta(
                spot,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "put"
              ) * 100
            ).toFixed(2);
            puts[i].theta = (
              greeks.getTheta(
                spot,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "put"
              ) * 100
            ).toFixed(2);
            puts[i].gamma = (
              greeks.getGamma(
                spot,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "put"
              ) * 100
            ).toFixed(2);
            puts[i].vega = (
              greeks.getVega(
                spot,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "put"
              ) * 100
            ).toFixed(2);
          }
        }
      });
    };

    computeGreeks();
  }, [symbolData, selectedSymbol, calls, puts]);

  const callsColSpan = showCalls ? 8 : 0;
  const putsColSpan = showPuts ? 7 : 0;

  // Formatting volume
  function formatVolume(volume) {
    if (volume >= 10000000) {
      return `${(volume / 10000000).toFixed(2)}Cr`;
    } else if (volume >= 100000) {
      return `${(volume / 100000).toFixed(2)}L`;
    } else if (volume >= 1000) {
      return `${(volume / 1000).toFixed(2)}K`;
    } else {
      return `${volume}`;
    }
  }

  const [buySellTagsCalls, setBuySellTagsCalls] = useState(
    calls ? new Array(calls.length).fill(null) : []
  );

  const [buySellTagsPuts, setBuySellTagsPuts] = useState(
    puts ? new Array(puts.length).fill(null) : []
  );

  const [scrollValue, setScrollValue] = useState(0);

  // Function to handle range input change
  const handleRangeChange = (e) => {
    setScrollValue(e.target.value);
    // Calculate the scroll position based on the range input value
    const scrollPosition =
      (e.target.value / 100) *
      (containerRef.current.scrollWidth - containerRef.current.clientWidth);
    containerRef.current.scrollLeft = scrollPosition;
  };

  const listenRightScrollEvent = (event) => {
    document.getElementById("left-side").scrollLeft = Math.abs(
      112 - event.currentTarget.scrollLeft
    );
  };

  const listenLeftScrollEvent = (event) => {
    document.getElementById("right-side").scrollLeft = Math.abs(
      112 - event.currentTarget.scrollLeft
    );
  };

  return (
    <div className="outer">
      <div
        ref={containerRef}
        className="option-chain-table"
        style={{ fontSize: "14px", overflowX: "auto" }}>
        <div className="calls-puts-header d-flex text-center border-bottom py-1">
          <div className="calls text-center fw-bold" style={{ width: "50%" }}>
            {showCalls && "Calls"}
          </div>
          <div className="puts text-center fw-bold" style={{ width: "50%" }}>
            {showPuts && "Puts"}
          </div>
        </div>
        <div
          className="table-responsive tabla parallax-table d-flex"
          style={{ position: "relative", height: "50%", left: "0px" }}>
          <table
            className="table table-responsive left-side"
            id="left-side"
            style={{ width: "90%" }}
            onScroll={(event) => listenLeftScrollEvent(event)}>
            <thead>
              <tr className="text-center">
                {showCalls && (
                  <>
                    {showOIBuilddup && (
                      <th scope="col" className="table-header-columns">
                        OI Buildup
                      </th>
                    )}
                    {showOIChng && (
                      <th scope="col" className="table-header-columns">
                        OI Chng
                      </th>
                    )}
                    {showVega && (
                      <th scope="col" className="table-header-columns">
                        Vega
                      </th>
                    )}

                    {showGamma && (
                      <th scope="col" className="table-header-columns">
                        Gamma
                      </th>
                    )}
                    {showTheta && (
                      <th scope="col" className="table-header-columns">
                        Theta
                      </th>
                    )}
                    {showDelta && (
                      <th scope="col" className="table-header-columns">
                        Delta
                      </th>
                    )}
                    <th scope="col" className="table-header-columns">
                      OI
                    </th>
                    <th scope="col" className="table-header-columns">
                      Volume
                    </th>
                    <th scope="col" className="table-header-columns">
                      Chng <br />
                      Chng%
                    </th>
                    <th scope="col" className="table-header-columns">
                      LTP
                    </th>
                    <th className="table-header-columns">Strike</th>
                    <th className="table-header-columns">IV</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {calls &&
                puts &&
                calls.map((call, index) => (
                  <tr key={call.instrument_name} className="text-center">
                    {showCalls && (
                      <>
                        {showOIBuilddup && (
                          <td
                            style={{
                              backgroundColor: getRowColor(call.strike, true),
                            }}></td>
                        )}
                        {showOIChng && (
                          <td
                            style={{
                              backgroundColor: getRowColor(call.strike, true),
                            }}></td>
                        )}
                        {showVega && (
                          <td
                            style={{
                              backgroundColor: getRowColor(call.strike, true),
                            }}>
                            {call["vega"]}
                          </td>
                        )}
                        {showGamma && (
                          <td
                            style={{
                              backgroundColor: getRowColor(call.strike, true),
                            }}>
                            {call["gamma"]}
                          </td>
                        )}
                        {showTheta && (
                          <td
                            style={{
                              backgroundColor: getRowColor(call.strike, true),
                            }}>
                            {call["theta"]}
                          </td>
                        )}
                        {showDelta && (
                          <td
                            style={{
                              backgroundColor: getRowColor(call.strike, true),
                            }}>
                            {call["delta"]}
                          </td>
                        )}
                        <td
                          data-side="calls"
                          style={{
                            backgroundColor: getRowColor(call.strike, true),
                          }}
                          onClick={(event) =>
                            handleRowClick(event, index, "calls")
                          }>
                          {formatVolume(
                            symbolData[call.ab_code]?.oi /
                              symbolData[call.ab_code]?.lot_size
                          )}
                        </td>
                        <td
                          data-side="calls"
                          style={{
                            backgroundColor: getRowColor(call.strike, true),
                          }}
                          onClick={(event) =>
                            handleRowClick(event, index, "calls")
                          }>
                          {formatVolume(
                            symbolData[call.ab_code]?.volume /
                              symbolData[call.ab_code]?.lot_size
                          )}
                        </td>

                        <td
                          data-side="calls"
                          style={{
                            backgroundColor: getRowColor(call.strike, true),
                          }}
                          onClick={(event) =>
                            handleRowClick(event, index, "calls")
                          }>
                          {selectedSide !== null &&
                            selectedRowIndex !== null &&
                            selectedSide == "calls" &&
                            selectedRowIndex == index &&
                            divVisibility && (
                              <div
                                style={{
                                  position: "absolute",
                                  zIndex: 1,
                                  left: "0px",
                                }}>
                                <span className="d-flex justify-content-center align-items-center gap-2">
                                  <input
                                    className="hover-lots-value px-1 text-end"
                                    value={lotSizeInput}
                                    onChange={(e) =>
                                      setLotSizeInput(e.target.value)
                                    }
                                  />
                                  <button
                                    className="buy-btn"
                                    onClick={handleBuyClick}>
                                    Buy
                                  </button>
                                  <button
                                    className="sell-btn"
                                    onClick={handleSellClick}>
                                    Sell
                                  </button>
                                </span>
                              </div>
                            )}
                          {symbolData[call.ab_code] && (
                            <span>
                              {symbolData[call.ab_code].change}
                              <span
                                style={{
                                  color:
                                    symbolData[call.ab_code].change > 0
                                      ? "#5CA81D"
                                      : "#DD6565",
                                  position: "sticky",
                                  left: 0,
                                }}>
                                <br />
                                {symbolData[call.ab_code].change_per}%
                              </span>
                            </span>
                          )}
                        </td>
                        <td
                          data-side="calls"
                          style={{
                            backgroundColor: getRowColor(call.strike, true),
                            position: "sticky",
                            left: 0,
                          }}
                          onClick={(event) =>
                            handleRowClick(event, index, "calls")
                          }>
                          {symbolData[call.ab_code]?.ltp}
                        </td>
                        <td className="strike">{call.strike}</td>
                        <td className="strike">
                          {call.iv && !isNaN(parseFloat(call.iv))
                            ? parseFloat(call.iv).toFixed(2)
                            : "N/A"}
                        </td>
                      </>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>

          <table
            className="table table-responsive right-side"
            id="right-side"
            style={{ width: "50%" }}
            onScroll={(event) => listenRightScrollEvent(event)}>
            <thead>
              <tr className="text-center">
                {showPuts && (
                  <>
                    <th scope="col" className="table-header-columns">
                      LTP
                    </th>
                    <th scope="col" className="table-header-columns">
                      Chng <br />
                      Chng%
                    </th>
                    <th scope="col" className="table-header-columns">
                      Volume
                    </th>
                    <th scope="col" className="table-header-columns">
                      OI
                    </th>
                    {showDelta && (
                      <th scope="col" className="table-header-columns">
                        Delta
                      </th>
                    )}
                    {showTheta && (
                      <th scope="col" className="table-header-columns">
                        Theta
                      </th>
                    )}

                    {showGamma && (
                      <th scope="col" className="table-header-columns">
                        Gamma
                      </th>
                    )}
                    {showVega && (
                      <th scope="col" className="table-header-columns">
                        Vega
                      </th>
                    )}
                    {showOIChng && (
                      <th scope="col" className="table-header-columns">
                        OI Chng
                      </th>
                    )}
                    {showOIBuilddup && (
                      <th scope="col" className="table-header-columns">
                        OI Buildup
                      </th>
                    )}
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {calls &&
                puts &&
                calls.map((call, index) => (
                  <tr key={call.instrument_name} className="text-center">
                    {showPuts && (
                      <>
                        <td
                          data-side="puts"
                          onClick={(event) =>
                            handleRowClick(event, index, "puts")
                          }
                          style={{
                            backgroundColor: getPutRowColor(puts[index].strike),
                          }}>
                          {symbolData[puts[index].ab_code]?.ltp}
                        </td>
                        <td
                          data-side="puts"
                          onClick={(event) =>
                            handleRowClick(event, index, "puts")
                          }
                          style={{
                            backgroundColor: getPutRowColor(puts[index].strike),
                          }}>
                          {selectedSide !== null &&
                            selectedRowIndex !== null &&
                            selectedSide == "puts" &&
                            selectedRowIndex == index &&
                            divVisibility && (
                              <div
                                style={{
                                  position: "absolute",
                                  zIndex: 1,
                                  right: "0px",
                                }}>
                                <span className="d-flex justify-content-center align-items-center gap-2">
                                  <input
                                    className="hover-lots-value px-1 text-end"
                                    value={lotSizeInput}
                                    onChange={(e) =>
                                      setLotSizeInput(e.target.value)
                                    }
                                  />
                                  <button
                                    className="buy-btn"
                                    onClick={handleBuyClick}>
                                    Buy
                                  </button>
                                  <button
                                    className="sell-btn"
                                    onClick={handleSellClick}>
                                    Sell
                                  </button>
                                </span>
                              </div>
                            )}
                          {symbolData[puts[index].ab_code] && (
                            <span>
                              {symbolData[puts[index].ab_code].change}
                              <span
                                style={{
                                  color:
                                    symbolData[puts[index].ab_code].change_per >
                                    0
                                      ? "#5CA81D"
                                      : "#DD6565",
                                }}>
                                <br />
                                {symbolData[puts[index].ab_code].change_per}%
                              </span>
                            </span>
                          )}
                        </td>
                        <td
                          data-side="puts"
                          onClick={(event) =>
                            handleRowClick(event, index, "puts")
                          }
                          style={{
                            backgroundColor: getPutRowColor(puts[index].strike),
                          }}>
                          {formatVolume(
                            symbolData[puts[index].ab_code]?.volume /
                              symbolData[puts[index].ab_code]?.lot_size
                          )}
                        </td>
                        <td
                          data-side="puts"
                          onClick={(event) =>
                            handleRowClick(event, index, "puts")
                          }
                          style={{
                            backgroundColor: getPutRowColor(puts[index].strike),
                          }}>
                          {formatVolume(
                            symbolData[puts[index].ab_code]?.oi /
                              symbolData[puts[index].ab_code]?.lot_size
                          )}
                        </td>
                        {showDelta && (
                          <td
                            style={{
                              backgroundColor: getPutRowColor(
                                puts[index].strike
                              ),
                            }}>
                            {puts[index]["delta"]}
                          </td>
                        )}
                        {showTheta && (
                          <td
                            style={{
                              backgroundColor: getPutRowColor(
                                puts[index].strike
                              ),
                            }}>
                            {puts[index]["theta"]}
                          </td>
                        )}
                        {showGamma && (
                          <td
                            style={{
                              backgroundColor: getPutRowColor(
                                puts[index].strike
                              ),
                            }}>
                            {puts[index]["gamma"]}
                          </td>
                        )}
                        {showVega && (
                          <td
                            style={{
                              backgroundColor: getPutRowColor(
                                puts[index].strike
                              ),
                            }}>
                            {puts[index]["vega"]}
                          </td>
                        )}
                        {showOIChng && (
                          <td
                            style={{
                              backgroundColor: getPutRowColor(
                                puts[index].strike
                              ),
                            }}></td>
                        )}
                        {showOIBuilddup && (
                          <td
                            style={{
                              backgroundColor: getPutRowColor(
                                puts[index].strike
                              ),
                            }}></td>
                        )}
                      </>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OptionChainTableMobile;
