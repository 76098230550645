// ExpiryDatesContext.js
import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { useOptionChainContext } from "./OptionChainContext";

const ExpiryDatesContext = createContext();

export const ExpiryDatesProvider = ({ children }) => {
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [activeDate, setActiveDate] = useState("");
  const { optionData, setOptionData, expiryDates, setExpiryDates } =
    useOptionChainContext();

  const handleSymbolSelection = async (symbol, date) => {
    sessionStorage.setItem("selectedSymbol", JSON.stringify(symbol));
    setSelectedSymbol(symbol);

    const formattedDate = date.replaceAll(" ", "%20");
    setSelectedDate(formattedDate);

    setActiveDate(date); // Setting the active date
    await fetchDataForSymbol(symbol.scrip, formattedDate); // Await the completion of the function
  };
  const fetchDataForSymbol = async (symbol, date) => {
    const apiEndpoint = `https://yvqy9ihwn6.execute-api.ap-south-1.amazonaws.com/default/optiongeeks_fyers_expirysymbols?id=${symbol}${date}`;

    try {
      const response = await axios.get(apiEndpoint);
      const data = response.data;
      setOptionData(data);

      console.log("Received data is: ", data);
      // console.log("hey");
    } catch (error) {
      console.log("Error: ", error);
    }
  };
  return (
    <ExpiryDatesContext.Provider
      value={{
        selectedSymbol,
        setSelectedSymbol,
        selectedDate,
        setSelectedDate,
        activeDate,
        setActiveDate,
        handleSymbolSelection,
      }}>
      {children}
    </ExpiryDatesContext.Provider>
  );
};

export const useExpiryDatesContext = () => useContext(ExpiryDatesContext);
