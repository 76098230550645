import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useFyers } from "../../context/FyersContext";

const OrdersDesktop = () => {
  const fyers = useFyers();
  const [orders, setOrders] = useState([]);
  const [filter, setFilter] = useState("all");
  // const access_token = localStorage.getItem("access_token");
  // const [symbol, setSymbol] = useState("");
  // const [qty, setQty] = useState(0);
  // this state is added to keep the track of checkbox
  const [checkboxEnabled, setCheckboxEnabled] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fyers.get_orders();
        console.log("My orders are:", response);
        if (response?.orderBook) {
          setOrders(response.orderBook);
        } else {
          setOrders([]);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, [fyers]);

  const statusValue = (status) => {
    switch (status) {
      case 1:
        return "Canceled";
      case 2:
        return "Traded / Filled";
      case 3:
        return "Not used currently";
      case 4:
        return "Transit";
      case 5:
        return "Rejected";
      case 6:
        return "Pending";
      case 7:
        return "Expired";
      default:
        return "";
    }
  };

  //changing date format. In received object it is different than what is shown in design
  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const time = dateTime.toLocaleTimeString([], {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
    const date = dateTime.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

    return `${time}, ${date}`;
  };

  const filteredOrders = orders.filter((order) => {
    if (filter === "executed" && order.status !== 2) {
      return false; // Skip non-executed orders
    }
    if (filter === "pending" && order.status !== 6) {
      return false; // Skip non-pending orders
    }
    if (filter === "rejected" && order.status !== 5) {
      return false; // Skip non-rejected orders
    }
    return true;
  });

  const isActiveButton = (buttonName) => {
    return filter === buttonName ? "active" : "";
  };

  // const hasPendingOrders = filteredOrders.some((order) => order.status === 6)/

  useEffect(() => {
    const hasPendingOrders = filteredOrders.some((order) => order.status === 6);
    setCheckboxEnabled(hasPendingOrders);
  }, [filteredOrders]);

  const handleCheckboxChange = (order) => {
    // Check if the order is pending before allowing changes
    if (order.status === 6) {
      const isChecked = selectedCheckboxes.includes(order.id);
      if (isChecked) {
        // Remove from selectedCheckboxes
        setSelectedCheckboxes(
          selectedCheckboxes.filter((id) => id !== order.id)
        );
      } else {
        // Add to selectedCheckboxes
        setSelectedCheckboxes([...selectedCheckboxes, order.id]);
      }
    }
  };

  const handleCancelSelected = () => {
    // Perform cancel action for selected orders based on selectedCheckboxes
    // ...

    // Clear selectedCheckboxes after performing cancel action
    setSelectedCheckboxes([]);
  };

  return (
    <>
      <Helmet>
        <title>Orders</title>
      </Helmet>
      <div className="orders-page">
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-between">
            <div className="btn-group-1 d-flex">
              <button
                className={`btn-1 all ${isActiveButton("all")}`}
                onClick={() => setFilter("all")}>
                All
              </button>
              <button
                className={`btn-1 executed ${isActiveButton("executed")}`}
                onClick={() => setFilter("executed")}>
                Executed
              </button>
              <button
                className={`btn-1 pending ${isActiveButton("pending")}`}
                onClick={() => setFilter("pending")}>
                Pending
              </button>
              <button
                className={`btn-1 rejected ${isActiveButton("rejected")}`}
                onClick={() => setFilter("rejected")}>
                Rejected
              </button>
            </div>
          </div>
          <div>
            <button
              className={`btn-1 exit ${
                selectedCheckboxes.length > 0 ? "enabled" : "disabled"
              }`}
              disabled={selectedCheckboxes.length === 0}
              onClick={handleCancelSelected}>
              Cancel Selected
            </button>
          </div>
        </div>
        {filteredOrders.length === 0 ? (
          <h2 className="mt-5 fw-bolder text-center">
            You don't have any {filter !== "all" ? filter : ""} orders!
          </h2>
        ) : (
          <table className="table table-borderless mt-3">
            <thead className="table-head">
              <tr className="mb-5">
                <th scope="col">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    disabled
                  />
                </th>
                <th scope="col">Instrument</th>
                <th scope="col">Product</th>
                <th scope="col">Type</th>
                <th scope="col">Filled Qty / Qty</th>
                <th scope="col">Avg price</th>
                <th scope="col">Trigger price</th>
                <th scope="col">B/S</th>
                <th scope="col">Order time</th>
                <th scope="col">Order ID</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders.map((order) => (
                <tr key={order.id}>
                  <td>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      disabled={order.status !== 6} // Disable for non-pending orders
                      checked={selectedCheckboxes.includes(order.id)}
                      onChange={() => handleCheckboxChange(order)}
                    />
                  </td>
                  <td>{order.symbol}</td>
                  <td className="product text-center">{order.productType}</td>
                  <td>Market</td>
                  <td className="text-center">{`${order.filledQty}/${order.qty}`}</td>
                  <td className="text-center">{order.tradedPrice}</td>
                  <td className="text-center">{order.stopPrice}</td>
                  <td
                    className={
                      order.side === -1 ? "text-danger" : "text-success"
                    }>
                    {order.side === -1 ? "SELL" : "BUY"}
                  </td>
                  <td>{formatDateTime(order.orderDateTime)}</td>
                  <td>{order.id}</td>
                  <td>
                    {statusValue(order.status)}
                    <span
                      role="button"
                      className="ms-2 info-icon rounded-circle"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={order.message}>
                      i
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default OrdersDesktop;
