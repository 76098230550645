import axios from "axios";

let symbolsData = null;

export const getSymbols = async () => {
  if (symbolsData) {
    return symbolsData;
  } else {
    try {
      const response = await axios.get(
        "https://un7cktyvnd.execute-api.ap-south-1.amazonaws.com/default/optiongeeks_fyers_allsymbols"
      );
      symbolsData = response.data;
      return symbolsData;
    } catch (error) {
      console.error("Error fetching symbols:", error);
      return null;
    }
  }
};