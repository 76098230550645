import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/header/Header";
import StrategyCreator from "./screens/StrategyCreator/StrategyCreator";
import Backtest from "./screens/Backtest";
import Positions from "./screens/Positions/Positions";
import Orders from "./screens/Orders/Orders";
import OptionChain from "./screens/OptionChain/OptionChain";
import Watchlist from "./screens/Watchlist";

function App() {
  return (
    <>
      <ToastContainer />
      <Header />
      <div className="grid-container">
        <Routes>
          <Route path="/" element={<StrategyCreator />} />
          <Route path="/backtest" element={<Backtest />} />
          <Route path="/optionchain" element={<OptionChain />} />
          <Route path="/positions" element={<Positions />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/watchlist" element={<Watchlist />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
