import React, { useContext, useEffect } from "react";
import StrategyMenu from "../components/StrategyMenu";
import ABWebSocketContext from "../context/ABWebsocketContext";
import AnalyseGraph from "../components/AnalyseGraph";
import { useGraphContext } from "../context/GraphContext";

const AnalyseMobile = ({
  parentCallback2,
  selectedData,
  selectedSymbol,
  type,
  index,
}) => {
  const { symbolData } = useContext(ABWebSocketContext);
  const {
    x_axis,
    setxAxis,
    y_axis,
    setyAxis,
    graphData,
    setGraphData,
    maxProfit,
    setMaxProfit,
    maxLoss,
    setMaxLoss,
    riskReward,
    setRiskReward,
    breakevenRange,
    setBreakeven,
    totalGreeks,
    setTotalGreeks,
    strategyDirection,
    setStrategyDirection,
    premium,
    setPremium,
  } = useGraphContext();

  useEffect(() => {
    parentCallback2(
      maxProfit,
      maxLoss,
      breakevenRange,
      selectedData,
      type,
      index
    );
  }, [breakevenRange, maxProfit, maxLoss]);
  return (
      <div 
          className=" modal "
          id="staticBackdropAnalyseMobile"
           data-bs-backdrop="static"
           tabIndex="-1"
           aria-labelledby="staticBackdropLabel"
           data-bs-keyboard="false"
           aria-hidden="true">
        <div className="modal-dialog">
          <div className="analyse-mobile-modal modal-content">
            <div className="modal-header" style={{ padding: "0px 12px" }}>
              <h1
                className="modal-title analyse-modal-title"
                id="exampleModalLabel">
                Analyse positions
              </h1>
              <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"></button>
            </div>
            <div
              className="modal-body analyse-chain-modal-body"
              style={{ padding: "0px 12px" }}>
              <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                {/* <PositionsTable /> */}
                <StrategyMenu />
                <AnalyseGraph
                  selectedData={selectedData}
                  ltp={symbolData[selectedSymbol?.ab_code]?.ltp}
                  symbol={selectedSymbol}
                  leastExpiry={selectedSymbol?.option_expiries[0]}
                  lotsVal={1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default AnalyseMobile;
