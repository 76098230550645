import { useContext, useEffect, useMemo } from "react";
import ABWebSocketContext from "../../context/ABWebsocketContext";

const HeaderLogic = () => {
  const { checkWebsocketConnection, subscribeToSymbols, symbolData } =
    useContext(ABWebSocketContext);
  const symbols = useMemo(() => [26000, 26009, 26017], []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const access_token = urlParams.get("access_token");
    const client_id = urlParams.get("client_id");
    const ws_token = urlParams.get("ws_token");
    if (access_token) {
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("client_id", client_id);
      localStorage.setItem("ws_token", ws_token);
      window.location.replace("https://app.optiongeeks.in");
    } else {
      console.log("Auth code not found.");
    }
  }, []);

  useEffect(() => {
    const connectionEstablised = checkWebsocketConnection();
    if (connectionEstablised) {
      subscribeToSymbols(symbols, "NSE");
    }
  }, [symbolData]);

  return {
    symbolData,
  };
};

export default HeaderLogic;
