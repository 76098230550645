import React from "react";
import { Helmet } from "react-helmet-async";
import PositionsMobile from "./PositionsMobile";
import PositionsDesktop from "./PositionsDesktop";

const Positions = () => {
  function isMobile() {
    return window.screen.width > 1280 ? false : true;
  }

  return isMobile() ? (
    <>
      <Helmet>
        <title>Positions</title>
      </Helmet>
      <div className="mobile-screen d-grid" style={{ gridColumn: "span 4" }}>
        <PositionsMobile />
      </div>
    </>
  ) : (
    <>
      <Helmet>
        <title>Positions</title>
      </Helmet>
      <div className="desktop-screenn">
        <PositionsDesktop />
      </div>
    </>
  );
};

export default Positions;
