import React from "react";
import { Helmet } from "react-helmet-async";


const Backtest = () => {
  
  return (
    <>
      <Helmet>
        <title>Backtest</title>
      </Helmet>
      {/* <div className="grid-container"> */}
      <h1>Backtest Page</h1>
      {/* </div> */}
    </>
  );
};

export default Backtest;
