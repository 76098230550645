import React from "react";
import { setAppId, setAccessToken } from "fyers-api-v2";
const fyers = require("fyers-api-v2");
// Set your app ID and access token
const access_token = localStorage.getItem("access_token");
// console.log("Access Token", access_token);
const app_id = "DF2HFNIFLU-102";
setAppId(app_id);
setAccessToken(access_token);

// fyers context
const FyersContext = React.createContext();

export const FyersProvider = ({ children }) => {
  return (
    // Provide the fyers object to the entire app
    <FyersContext.Provider value={fyers}>{children}</FyersContext.Provider>
  );
};

export const useFyers = () => {
  const fyers = React.useContext(FyersContext);
  if (!fyers) {
    throw new Error("Error in Fyers");
  }
  return fyers;
};
