import React from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../images/Logo.svg";
import UserIcon from "../../images/user-icon.svg";
const HeaderDesktop = ({ symbolData }) => {
  function formatNumberWithCommasAndFontWeight(value) {
    const formattedValue = parseFloat(value).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const parts = formattedValue.split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1];

    return (
      <span>
        {integerPart}.<span style={{ fontWeight: 400 }}>{decimalPart}</span>
      </span>
    );
  }
  return (
    <div className="shadow-sm">
      <div className="grid-container">
        <nav
          className="header fixed-top navbar navbar-expand-lg shadow-mb-sm bg-white"
          style={{ zIndex: "999" }}>
          <div
            className="container-fluid  bg-white"
            style={{ maxWidth: "1392px" }}>
            <Link
              className="navbar-brand d-flex align-items-center justify-content-center  "
              to="/">
              <img src={Logo} alt="logo" className="app-logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 ms-md-3 ps-md-5 gap-md-3 text-decoration-none">
                {/* <li className="nav-item mb-2 mb-md-0">
                  <NavLink
                    // className="nav-link navigation"
                    className={({ isActive }) =>
                      isActive ? "active" : "inactive"
                    }
                    to="/backtest">
                    Backtest
                  </NavLink>
                </li>{" "} */}
                <li className="nav-item mb-2 mb-md-0">
                  <NavLink
                    // className="nav-link navigation"
                    className={({ isActive }) =>
                      isActive ? "active" : "inactive"
                    }
                    to="/">
                    Strategy creator
                  </NavLink>
                </li>{" "}
                <li className="nav-item mb-2 mb-md-0">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active" : "inactive"
                    }
                    to="/optionchain">
                    Option chain
                  </NavLink>
                </li>{" "}
                <li className="nav-item mb-2 mb-md-0">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active" : "inactive"
                    }
                    to="/positions">
                    Positions
                  </NavLink>
                </li>{" "}
                <li className="nav-item mb-2 mb-md-0">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active" : "inactive"
                    }
                    to="/orders">
                    Orders
                  </NavLink>
                </li>
              </ul>
              <div className="d-flex flex-column flex-md-row list-unstyled justify-content-start justify-content-md-between" style={{"lineHeight": "normal"}}>
                <li className=" nav-item">
                  <Link className="nav-link " aria-current="page">
                    <div className="symbol-containers d-flex flex-column align-items-md-start">
                      <div className="symbol-name">NIFTY</div>
                      <div>
                        <span className="nifty">
                          {symbolData["26000"]?.ltp.includes(".")
                            ? formatNumberWithCommasAndFontWeight(
                                symbolData["26000"]?.ltp
                              )
                            : formatNumberWithCommasAndFontWeight(
                                symbolData["26000"]?.ltp
                              )}
                        </span>

                        <span
                          className={`ch-chp me-2 ${
                            symbolData["26000"]?.change > 0
                              ? "text-success"
                              : "text-danger"
                          }`}>
                          {symbolData["26000"]?.change > 0 ? "+" : ""}
                          {symbolData["26000"]?.change}
                          {symbolData["26000"]?.change_per ? "(" : ""}
                          {symbolData["26000"]?.change_per}
                          {symbolData["26000"]?.change_per ? "%)" : "-"}
                          {/* {symbolData["101000000026000"]?.change > 0 ? "+" : ""}
                          {symbolData["101000000026000"]?.change}
                          {symbolData["101000000026000"]?.change_per ? "(" : ""}
                          {symbolData["101000000026000"]?.change_per}
                          {symbolData["101000000026000"]?.change_per  */}
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li className=" nav-item">
                  <Link className="nav-link" aria-current="page">
                    <div className="symbol-containers d-flex flex-column align-items-md-start">
                      <div className="symbol-name">BANKNIFTY</div>
                      <div>
                        <span className="nifty">
                          {symbolData["26009"]?.ltp.includes(".")
                            ? formatNumberWithCommasAndFontWeight(
                                symbolData["26009"]?.ltp
                              )
                            : formatNumberWithCommasAndFontWeight(
                                symbolData["26009"]?.ltp
                              )}
                        </span>

                        <span
                          className={`ch-chp me-2 ${
                            symbolData["26009"]?.change > 0
                              ? "text-success"
                              : "text-danger"
                          }`}>
                          {symbolData["26009"]?.change > 0 ? "+" : ""}
                          {symbolData["26009"]?.change}
                          {symbolData["26009"]?.change_per ? "(" : ""}
                          {symbolData["26009"]?.change_per}
                          {symbolData["26009"]?.change_per ? "%)" : "-"}
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li className=" nav-item">
                  <Link className="nav-link " aria-current="page">
                    <div className="symbol-containers d-flex flex-column align-items-md-start">
                      <div className="symbol-name">INDIA VIX</div>
                      <div>
                        <span className="nifty">
                          {symbolData["26017"]?.ltp.includes(".")
                            ? formatNumberWithCommasAndFontWeight(
                                symbolData["26017"]?.ltp
                              )
                            : formatNumberWithCommasAndFontWeight(
                                symbolData["26017"]?.ltp
                              )}
                        </span>

                        <span
                          className={`ch-chp me-2 ${
                            symbolData["26017"]?.change > 0
                              ? "text-success"
                              : "text-danger"
                          }`}>
                          {symbolData["26017"]?.change > 0 ? "+" : ""}
                          {symbolData["26017"]?.change}
                          {symbolData["26017"]?.change_per ? "(" : ""}
                          {symbolData["26017"]?.change_per}
                          {symbolData["26017"]?.change_per ? "%)" : "-"}
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
              </div>

              <div className="d-flex justify-content-center align-items-center gap-2">
                <img
                  src={UserIcon}
                  alt="User Profile Pic"
                  style={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "50%",
                  }}
                  type="button"
                />
                <div className="d-flex flex-column gap-2">
                  <span className="broker-id">
                    ABC1234M
                    <span className="header-dropdown">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="8"
                        viewBox="0 0 13 8"
                        fill="none"
                        className="ms-1">
                        <path
                          d="M12 1L6.5 7L1 1"
                          stroke="#1E2028"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </span>
                  <span className="broker-name">Broker name</span>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default HeaderDesktop;
