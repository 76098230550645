import React, {
  createContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import unPackUDP from "../utils/decoder";
import axios, { all } from "axios";

const WebSocketContext = createContext(null);
const access_token = localStorage.getItem("access_token");
const app_code = "DF2HFNIFLU-102";

export const WebSocketProvider = ({ children }) => {
  const location = useLocation();
  const conn = useRef(null);
  const previousPathname = useRef(null);
  const [symbolList, setSymbolList] = useState({});
  const [readyState, setReadyState] = useState(false);

  const [msg, setMsg] = useState({});
  const [symbolTokenMap, setSymbolTokenMap] = useState({});
  const [symbolData, setSymbolData] = useState({});

  let temp_symbols = {};

  const MARKET_DATA_URL = `wss://api.fyers.in/socket/v2/dataSock?access_token=${app_code}:${access_token}`;
  const apiEndpoint = `https://jywfrpr3t9.execute-api.ap-south-1.amazonaws.com/default/testpython`;

  useLayoutEffect(() => {
    handleNavigation();

    // TODO: Uncomment heart beat

    // setInterval(() => {
    //   if (conn.current.readyState === WebSocket.OPEN && readyState) {
    //     conn.current.send("ping");
    //   }
    //   if (conn.current.readyState === WebSocket.CLOSED) {
    //     setReadyState(false);
    //   }
    // }, 5000);

    return () => { };
  }, [location.pathname, readyState, symbolList]);

  useEffect(() => {
    if (msg) {
      setSymbolData((prevData) => ({
        ...prevData,
        [Object.keys(msg)]: msg[Object.keys(msg)],
      }));
    }
  }, [msg]);

  useEffect(() => {
    conn.current.onmessage = async (event) => {
      if (typeof event.data === "string") {
        let formatted_msg = JSON.parse(event.data);
        if (formatted_msg["s"] === "ok") {
          setReadyState(true);
        }
        if (formatted_msg === "pong") return;
        if (formatted_msg.hasOwnProperty("d") || formatted_msg.code === 0)
          return;
        if (formatted_msg["s"] === "error") return;
      } else {
        if (Object.keys(symbolList).length !== 0) {
          let array_buffer = await event.data.arrayBuffer();
          const decodedMsg = unPackUDP(array_buffer, symbolList);
          try {
            // console.log(decodedMsg.d[7208][0]);
            let required_format = {
              [decodedMsg.d[7208][0].n]: {
                ltp: parseFloat(decodedMsg.d[7208][0].v.lp).toFixed(2),
                change: parseFloat(decodedMsg.d[7208][0].v.ch).toFixed(2),
                change_per: parseFloat(decodedMsg.d[7208][0].v.chp).toFixed(2),
                open: parseFloat(decodedMsg.d[7208][0].v.open_price).toFixed(2),
                high: parseFloat(decodedMsg.d[7208][0].v.high_price).toFixed(2),
                low: parseFloat(decodedMsg.d[7208][0].v.low_price).toFixed(2),
                volume: parseFloat(decodedMsg.d[7208][0].v.volume),
                prev_close: parseFloat(
                  decodedMsg.d[7208][0].v.prev_close_price
                ).toFixed(2),
              },
            };
            if (decodedMsg["s"] == "ok") {
              setMsg(required_format);
            }
          } catch {
            console.log("Missing token");
          }
        } else {
          setSymbolList(temp_symbols);
        }
      }
    };
  }, [symbolList, readyState, temp_symbols]);

  const handleNavigation = () => {
    if (location.pathname !== previousPathname.current) {
      previousPathname.current = location.pathname;
    }
  };

  const updateSymbolList = (symbols, action) => {
    let updatedlist = {};
    if (action === "add") {
      updatedlist = Object.assign(symbolList, symbols);
    } else {
      updatedlist = Object.keys(symbolList).reduce((acc, key) => {
        if (!symbols.hasOwnProperty(key)) {
          acc[key] = symbolList[key];
        }
        return acc;
      }, {});
    }
    setSymbolList(updatedlist);
    temp_symbols = updatedlist;
  };

  const checkWebsocketConnection = () => {
    if (!conn.current) {
      openWebSocket();
    }
    if (!(conn.current.readyState === WebSocket.OPEN && readyState)) {
      waitForSocketConnection(conn.current, function () { });
    }
    if (conn.current.readyState === WebSocket.OPEN) return true;
    // else return false;
    return true;
  };

  const subscribeToSymbols = (symbols) => {
    var curTime = new Date();
    var day = curTime.getDay();
    curTime = parseInt(
      curTime.getHours() +
      "" +
      ("0" + curTime.getMinutes()).substr(-2) +
      "" +
      ("0" + curTime.getSeconds()).substr(-2)
    );
    // Check if the Market is Closed and use API to get Last Available Data
    if (
      ((curTime > 110000 || curTime < 90000) && day > 0 && day < 6) ||
      day == 0 ||
      day == 6
    ) {
      // console.log("It's a good time!", day, curTime);
      getPriceAPI(symbols);
    } else {
      if (conn.current.readyState === WebSocket.OPEN && readyState) {
        // Add condition to subscribe to symbols which are not subscribed
        const symbolsKeys = Object.keys(symbols);
        const symbolListKeys = Object.keys(symbolList);

        // Find keys in symbols that are not present in symbolList
        const uniqueKeys = symbolsKeys.filter(
          (key) => !symbolListKeys.includes(key)
        );
        // Create variables with the unique keys and their corresponding values from symbols
        const subscribe_list = [];
        uniqueKeys.forEach((key) => {
          subscribe_list.push(symbols[key]);
        });

        updateSymbolList(symbols, "add");
        if (subscribe_list.length !== 0) {
          const message = JSON.stringify({
            T: "SUB_L2",
            L2LIST: subscribe_list,
            SUB_T: 1,
          });
          // console.log(subscribe_list);
          conn.current.send(message);
        }
      }
    }
    // console.log("It's not a good time!");
    return true;
  };
  const getPriceAPI = async (symbols) => {
    const symbolsKeys = Object.keys(symbols);
    const symbolListKeys = Object.keys(symbolList);
    // console.log(symbols,symbolListKeys)
    // Find keys in symbols that are not present in symbolList
    const uniqueKeys = symbolsKeys.filter(
      (key) => !symbolListKeys.includes(key)
    );
    // console.log(symbolList,uniqueKeys)
    // const uniqueKeys=symbolsKeys
    // console.log(uniqueKeys)
    // Create variables with the unique keys and their corresponding values from symbols
    const subscribe_list = [];
    uniqueKeys.forEach((key) => {
      subscribe_list.push(symbols[key]);
    });
    // subscribe_list.push("NSE:NIFTY50-INDEX")
    // uniqueKeys.push('101000000026000')
    // subscribe_list.push("NSE:NIFTYBANK-INDEX")
    // uniqueKeys.push('101000000026009')
    // 101000000026000: "NSE:NIFTY50-INDEX",
    // 101000000026009: "NSE:NIFTYBANK-INDEX",
    updateSymbolList(symbols, "add");
    // console.log(subscribe_list)
    try {
      const allres = await Promise.allSettled(
        subscribe_list.map(async (obj, i) => {
          const body = {
            access_token: access_token,
            trading_symbol: obj,
            code: symbolsKeys[i],
          };
          // const response = axios.post(apiEndpoint, body);
          const response = await axios.post(apiEndpoint, body);
          const data = response.data;
          // console.log(data);
          // console.log( {[uniqueKeys[i]]:data});
          // const toAdd = { [uniqueKeys[i]]: data }
          setSymbolData((prevData) => ({
            ...prevData,
            [uniqueKeys[i]]: data[uniqueKeys[i]],
            // [Object.keys(msg)]: msg[Object.keys(msg)],
          }));
          // symbolData[uniqueKeys[i]] = data[uniqueKeys[i]];
          // setSymbolData({ ...symbolData })
        })
      );
      // console.log(allres)
    } catch (error) {
      console.log("Error: ", error);
    }

    // console.log(allres)
    // for (let i = 0; i < subscribe_list.length; i++) {
    //   const body = {
    //     "access_token": access_token,
    //     trading_symbol: subscribe_list[i],
    //     code: uniqueKeys[i]
    //   }
    //   try {
    //     const response = await axios.post(apiEndpoint, body);
    //     const data = response.data;
    //     // console.log(data);
    //     // console.log( {[uniqueKeys[i]]:data});
    //     // const toAdd = { [uniqueKeys[i]]: data }
    //     symbolData[uniqueKeys[i]] = data[uniqueKeys[i]];
    //     setSymbolData({ ...symbolData })
    //     // setSymbolData({ ...symbolData,  [uniqueKeys[i]]: data } );
    //     // console.log(symbolData)
    //     // setSymbolData((prevData) => ({
    //     //   ...prevData,

    //     //   [Object.keys(data)]: msg[Object.keys(data)],
    //     // }));
    //     // return data
    //   }
    //   catch (error) {
    //     console.log("Error: ", error);

    //   }
    // }
  }
  const unsubscribeToSymbols = (symbols) => {
    var curTime = new Date();
    var day = curTime.getDay();
    curTime = parseInt(
      curTime.getHours() +
      "" +
      ("0" + curTime.getMinutes()).substr(-2) +
      "" +
      ("0" + curTime.getSeconds()).substr(-2)
    );
    // Check if the Market is Closed and use API to get Last Available Data
    if (
      ((curTime > 153000 || curTime < 91500) && day > 0 && day < 6) ||
      day == 0 ||
      day == 6
    ) {
      const subscribe_list = [];
      const symbolsKeys = Object.keys(symbols);
      symbolsKeys.forEach((key) => {
        subscribe_list.push(symbols[key]);
      });
      // console.log(symbols)
      updateSymbolList(symbols, "remove");
      setTimeout(() => {
        subscribeToSymbols(symbols);
      }, 500);
      // console.log("It's a good time!", day, curTime);
      // getPriceAPI(symbols);
    } else {
      if (conn.current.readyState === WebSocket.OPEN && readyState) {
        const subscribe_list = [];
        const symbolsKeys = Object.keys(symbols);
        symbolsKeys.forEach((key) => {
          subscribe_list.push(symbols[key]);
        });
        updateSymbolList(symbols, "remove");
        const message = JSON.stringify({
          T: "SUB_L2",
          L2LIST: subscribe_list,
          SUB_T: 0,
        });
        conn.current.send(message);
      }
    }
  };

  function waitForSocketConnection(socket, callback) {
    setTimeout(function () {
      if (socket.readyState === 1) {
        if (callback != null) {
          callback();
        }
      } else {
        waitForSocketConnection(socket, callback);
      }
    }, 5); // wait 5 milisecond for the connection...
  }

  const openWebSocket = () => {
    if (!conn.current || conn.current.readyState !== WebSocket.OPEN) {
      conn.current = new WebSocket(MARKET_DATA_URL);
    }
  };

  return (
    <WebSocketContext.Provider
      value={{
        websocketConnection: conn.current,
        checkWebsocketConnection,
        subscribeToSymbols,
        unsubscribeToSymbols,
        updateSymbolList,
        symbolList,
        symbolData, // Include the "msg" object in the context value
      }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketContext;
