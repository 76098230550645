import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useContext,
} from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useGraphContext } from "../context/GraphContext";
import ABWebSocketContext from "../context/ABWebsocketContext";
// import {erf} from 'mathjs';
import impliedVolatility from "implied-volatility";

var iv = require("implied-volatility");
var jStat = require("jstat");
var greeks = require("greeks");

const AnalyseGraph = ({ selectedData, ltp, symbol, leastExpiry, lotsVal }) => {
  const {
    x_axis,
    setxAxis,
    y_axis,
    setyAxis,
    graphData,
    setGraphData,
    maxProfit,
    setMaxProfit,
    maxLoss,
    setMaxLoss,
    riskReward,
    setRiskReward,
    breakevenRange,
    setBreakeven,
    totalGreeks,
    setTotalGreeks,
    strategyDirection,
    setStrategyDirection,
    premium,
    setPremium,
    greenRange,
    setGreenRange
  } = useGraphContext();
  const [ltpIndex, setLtpIndex] = useState(0);
  const [activeButton, setActiveButton] = useState("payoffchart");
  const {
    updateSymbolList,
    websocketConnection,
    checkWebsocketConnection,
    subscribeToSymbols,
    unsubscribeToSymbols,
    symbolList,
    symbolData,
  } = useContext(ABWebSocketContext);
  const chartComponent = useRef(null);
  const [linechart, setlineChart] = useState({
    xAxis: {
      tickInterval: 100,
      categories: x_axis,
      title: false,
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      title: {
        enabled: false,
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
      },
    },
    series: [
      {
        name: "",
        color: "#D8FDE2",
        opacity: 0.36,
        negativeColor: "#FFD9D7",
        showInLegend: false,
      },
    ],
    chart: {
      type: "area",
    },
    title: {
      text: "",
    },
  });

  const updateChart = () => {
    if (!x_axis.length || !y_axis.length) {
      return; // Don't update chart with invalid data
    }
    linechart.series[0] = {
      type: "area",
      data: y_axis,
      name: "P&L",
      color: "#ACF1BF",
      opacity: 0.7,
      negativeColor: "#FFC2BE",
      lineWidth: 1,
      showInLegend: false,
      marker: {
        enabled: false
    }
    };
    linechart.yAxis = {
      gridLineColor: '#EFEFEF',
      tickInterval: 10000,
      plotLines: [
        {
          value: 0,
          width: 1,
          color: '#efefef',
        },
      ],
      title: {
        enabled: false,
      },
    };
    linechart.xAxis = {
      alternateGridColor: "#fff",
      tickInterval: 8,
      allowDecimals: false,
      categories: x_axis,
      plotLines: [
        {
          color: "#B7BAFA",
          width: 1,
          value: ltpIndex,
          zIndex: 2,
          allowDecimals: false,
          label: {
            style: {
              fontWeight: "bold",
            },
            text: "Spot - " + ltp,
            textAlign: "center",
            rotation: 0,
            zIndex: 2,
            y: 0,
          },
        },
      ],
    };

    setlineChart(linechart);
    Highcharts.chart("container", linechart);
  };

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  // for graph table:
  //for range slider
  const [sliderValue, setSliderValue] = useState(0);
  const [lowerLimit, setlower] = useState(0);
  const [duplicateVal, setdup] = useState(0);
  const [upperLimit, setupper] = useState(100);
  const [left, setleft] = useState(0);
  const [diff, setdiff] = useState(0);
  const [symb, setsymbol] = useState(null);
  const [expiries, setExpiries] = useState([]);
  const [selectedDate, setDate] = useState(new Date());
  const [chartError, setChartError] = useState(null);

  useEffect(() => {
    if (ltp && !duplicateVal) {
      setdup(ltp);
      formXaxis();
    }
  }, [ltp]);

  const formXaxis = () => {
    let step = symbol?.step;
    const roundedValue = Math.round(ltp / step) * step;

    const lowerBound = roundedValue - (roundedValue * 0.08);
    const upperBound = roundedValue + (roundedValue * 0.08);

    // Generate the array with the specified step
    let valuesArray = [];
    for (let value = lowerBound; value <= upperBound; value += step) {
      let step_rounded = Math.floor(value / step) * step;
      valuesArray.push(step_rounded);
    }
    let newArray = [...valuesArray];
    let low = 0;
    let high = valuesArray.length - 1;
    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      if (valuesArray[mid] === ltp) {
        // If the element already exists, you can handle it as needed
        return;
      } else if (valuesArray[mid] < ltp) {
        low = mid + 1;
      } else {
        high = mid - 1;
      }
    }
    setLtpIndex(low);
    newArray.splice(low, 0, parseFloat(ltp));
    setxAxis(newArray);
  };

  const formYaxis = () => {
    let checkedarrays = [];
    let g = [];

    selectedData.map((obj, i) => {
      checkedarrays.push(obj);
    });
    let h = [];

    let premium = 0;
    lotsVal = parseInt(lotsVal);

    checkedarrays.map((obj) => {
      let strike = Number(obj.strike);
      let option_price = Number(obj?.average_prc);
      let option_type = obj.isCALL ? "CE" : "PE";
      let y = [];
      let option_lot = lotsVal * Number(obj.lotSize);
      let price = obj.isBuy
        ? -(option_price * option_lot)
        : option_price * option_lot;
      premium = premium + price;

      if (option_type == "CE") {
        for (let i = 0; i < x_axis.length; i++) {
          let num1 = x_axis[i] - strike - option_price;
          let num2 = 0 - option_price;
          let m = Math.max(Number(num1), Number(num2));
          y.push(m * option_lot);
        }
      } else {
        for (let i = 0; i < x_axis.length; i++) {
          let num1 = strike - x_axis[i] - option_price;
          let num2 = 0 - option_price;
          let m = Math.max(Number(num1), Number(num2));
          y.push(m * option_lot);
        }
      }
      if (obj.isBuy == 0) {
        // if (obj.buy_sell == 'SELL') {
        for (let i = 0; i < y.length; i++) {
          y[i] = -y[i];
        }
      }
      if (h.length == 0) {
        for (let i = 0; i < y.length; i++) {
          // g.push(Math.round(Number(y[i]), 2));
          g[i] = Math.round(y[i] * 100) / 100;
        }
      } else {
        for (let i = 0; i < y.length; i++) {
          let num = g[i] + Number(y[i]);
          g[i] = Math.round(num * 100) / 100;
        }
      }
      h = g;
      // Calculate maxProfit and maxLoss
      const maxProfit = Math.max(...h);
      const maxLoss = Math.min(...h);
      let calculatedMaxProfit;
      let calculatedMaxLoss;
      const maxProfitIndex = h.indexOf(maxProfit);
      if (
        maxProfitIndex === h.length - 1 &&
        h[maxProfitIndex] > h[maxProfitIndex - 1]
      ) {
        calculatedMaxProfit = "Unlimited";
      } else if (
        maxProfitIndex === 0 &&
        h[maxProfitIndex] > h[maxProfitIndex + 1]
      ) {
        calculatedMaxProfit = "Unlimited";
      } else {
        calculatedMaxProfit = maxProfit;
      }
      const maxLossIndex = h.indexOf(maxLoss);
      if (
        maxLossIndex === h.length - 1 &&
        h[maxLossIndex] < h[maxLossIndex - 1]
      ) {
        calculatedMaxLoss = "Unlimited";
      } else if (maxLossIndex === 0 && h[maxLossIndex] < h[maxLossIndex + 1]) {
        calculatedMaxLoss = "Unlimited";
      } else {
        calculatedMaxLoss = maxLoss;
      }

      let calculatedRiskReward;
      if (
        calculatedMaxProfit === "Unlimited" ||
        calculatedMaxLoss === "Unlimited"
      ) {
        calculatedRiskReward = "N/A";
      } else {
        calculatedRiskReward = Math.abs(
          parseFloat(calculatedMaxProfit / calculatedMaxLoss).toFixed(2)
        );
        if (Math.abs(calculatedMaxProfit) > Math.abs(calculatedMaxLoss)) {
          calculatedRiskReward = calculatedRiskReward + ":1";
        } else {
          calculatedRiskReward = "1:" + calculatedRiskReward;
        }
      }
      let breakevenRange = "";
      let strategyDirection = "";
      let green_range = [];

      const indices = [];
      for (let i = 1; i < h.length; i++) {
        if ((h[i - 1] < 0 && h[i] >= 0) || (h[i - 1] >= 0 && h[i] < 0)) {
          indices.push(x_axis[i]);
        }
      }
      if (indices.length == 1) {
        if (h[h.length - 1] > 0) {
          let percent_diff = parseFloat(
            ((indices[0] - ltp) / ltp) * 100
          ).toFixed(2);
          breakevenRange = indices[0] + "(" + percent_diff + "%) >";
          strategyDirection = "Bullish strategy";
          green_range.push(indices[0]);
          green_range.push(x_axis[x_axis.length-1]);
        } else {
          let percent_diff = parseFloat(
            ((indices[0] - ltp) / ltp) * 100
          ).toFixed(2);
          breakevenRange = "< " + indices[0] + "(" + percent_diff + "%)";
          strategyDirection = "Bearish strategy";
          green_range.push(x_axis[0]);
          green_range.push(indices[0]);
        }
      }
      if (indices.length >= 2) {
        if (h[h.length - 1] > 0) {
          let percent_diff = parseFloat(
            ((indices[1] - ltp) / ltp) * 100
          ).toFixed(2);
          breakevenRange = indices[1] + "(" + percent_diff + "%) >";
          strategyDirection = "Neutral strategy";
        }
        if (h[0] > 0) {
          let percent_diff = parseFloat(
            ((indices[0] - ltp) / ltp) * 100
          ).toFixed(2);
          breakevenRange =
            "< " +
            indices[0] +
            "(" +
            percent_diff +
            "%)" +
            " & " +
            breakevenRange;
          strategyDirection = "Neutral strategy";
          green_range.push(x_axis[0]);
          green_range.push(indices[0]);
          green_range.push(indices[1]);
          green_range.push(x_axis[x_axis.length-1]);
        }
        if (
          h[x_axis.indexOf(indices[0])] > 0 &&
          h[x_axis.indexOf(indices[0]) - 1] <= 0 &&
          h[x_axis.indexOf(indices[1]) - 1] >= 0 &&
          h[x_axis.indexOf(indices[1]) + 1] < 0
        ) {
          let percent_diff = parseFloat(
            ((indices[0] - ltp) / ltp) * 100
          ).toFixed(2);
          breakevenRange = indices[0] + "(" + percent_diff + "%)";
          percent_diff = parseFloat(((indices[1] - ltp) / ltp) * 100).toFixed(
            2
          );
          breakevenRange =
            breakevenRange +
            " < Spot < " +
            indices[1] +
            "(" +
            percent_diff +
            "%)";
          strategyDirection = "Neutral strategy";
          green_range.push(indices[0]);
          green_range.push(indices[1]);
        }
      }
      setMaxProfit(calculatedMaxProfit);
      setMaxLoss(calculatedMaxLoss);
      setRiskReward(calculatedRiskReward);
      setBreakeven(breakevenRange);
      setStrategyDirection(strategyDirection);
      setGreenRange(green_range);
    });
    setPremium(premium?.toFixed(2));
    setyAxis(h);
    if (h[0] === NaN) {
      formYaxis();
      return;
    }
  };
  useEffect(() => {
    if (ltp) {
      setdup(ltp);
    }
  }, [symbol]);

  useEffect(() => {
    if (y_axis.length > 0) {
      updateChart();
    }
  }, [y_axis]);

  useEffect(() => {
    let flag = true;
    selectedData.map((row, index) => {
      if (!row.price) {
        flag = false;
      }
    });
    if (
      flag &&
      graphData !== selectedData &&
      (symb?.scrip === selectedData[0]?.scrip || selectedData.length === 0)
    ) {
      formYaxis();
      setGraphData(selectedData);
    }
  }, [selectedData, symbolData, graphData, x_axis]);

  useEffect(() => {
    let dt = new Date(leastExpiry);
    let varb = 0;
    let leastdate = new Date(selectedData[0]?.expiry);
    selectedData.map((o) => {
      if (o == leastExpiry) {
        varb = true;
      }
      let ddate = new Date(o.expiry);
      leastdate = new Date(Math.min(ddate, leastdate));
    });
    if (!varb) {
      dt = new Date(leastdate);
      setDate(dt);
      let exd = new Date();
      let g = Math.round((dt - exd) / (1000 * 60 * 60 * 24)) + 1;
      setdaysleft(g);
    }
    let td = new Date();
    let dates = [];
    let date = new Date();
    while (date <= dt) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    dates.push(new Date(date));
    setExpiries(dates);
  }, [leastExpiry, selectedData]);

  useEffect(() => {
    setSliderValue(duplicateVal);
    let lval;
    let uval;
    let lpp = duplicateVal;
    let rval = Math.round((lpp / 10) * 100) / 100;
    lval = lpp - rval;
    uval = Math.round(lpp) + rval;
    setlower(lval);
    setupper(uval);
    // setsymbol(symbol);
    setdiff(0);
  }, [duplicateVal]);

  useEffect(() => {
    let j = ((sliderValue - lowerLimit) / (upperLimit - lowerLimit)) * 100;
    setleft(j);
  }, [sliderValue]);

  const [daysleft, setdaysleft] = useState(0);
  const handleDateClick = (event) => {
    let date = event.target.innerHTML;
    date = new Date(date);
    setDate(date);
    let exd = new Date();
    let g = Math.round((date - exd) / (1000 * 60 * 60 * 24)) + 1;
    setdaysleft(g);
  };

  const handleSliderChange = (event) => {
    const value = parseInt(event.target.value);
    setSliderValue(value);
    if (duplicateVal) {
      let diff = value - Math.round(duplicateVal);
      diff /= duplicateVal;
      diff *= 100;
      diff = Math.round(diff * 100) / 100;
      setdiff(diff);
    }
  };
  const [lotsValue, setLotsValue] = useState(1);

  const decrementLots = () => {
    if (lotsValue > 1) {
      setLotsValue(lotsValue - 1);
    }
  };

  const incrementLots = () => {
    setLotsValue(lotsValue + 1);
  };

  useEffect(() => {
    formYaxis();
    updateChart();
  }, [selectedData, x_axis]);

  return (
    <div className="strategy-graph d-flex flex-column justify-content-between p-1 p-md-3 w-100">
      <div className="graph-btns d-flex gap-2">
        <button
          className={`filter-btns ${
            activeButton === "payoffchart" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("payoffchart")}>
          Payoff chart
        </button>
        {/* <button
          className={`filter-btns ${
            activeButton === "p&ltable" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("p&ltable")}>
          P&L table
        </button> */}
      </div>

      {/* <div className="d-flex justify-content-end align-items-center">
        <span className="graph-text">Standard deviation </span>
        <svg
          className="ms-2 me-3"
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none">
          <path
            d="M7.00004 8.1667V7.71721C7.00004 7.13791 7.44495 6.65974 7.94111 6.36071C8.42012 6.07203 8.8775 5.55888 8.75004 4.66667C8.50004 2.91666 6.12504 3.79168 6.12504 3.79168M7.00004 9.91668V10.2083M13.4167 7.00001C13.4167 10.5438 10.5439 13.4167 7.00004 13.4167C3.45621 13.4167 0.583374 10.5438 0.583374 7.00001C0.583374 3.45618 3.45621 0.583344 7.00004 0.583344C10.5439 0.583344 13.4167 3.45618 13.4167 7.00001Z"
            stroke="#555555"
            strokeLinecap="round"
            strokeLinejoin="round"
          />{" "}
        </svg>
        <span>
          <div className="strategy-lots d-flex align-items-center justify-content-between px-2">
            <span
              role="button"
              onClick={decrementLots}
              style={{
                color: "#C1C1C1",
                fontSize: "20px",
                fontWeight: "500",
                lineHeight: "0px",
                cursor: "pointer",
              }}>
              -
            </span>
            <span>{lotsValue}</span>
            <span
              role="button"
              onClick={incrementLots}
              className="plus"
              style={{
                color: "#C1C1C1",
                fontSize: "20px",
                fontWeight: "500",
                lineHeight: "0px",
                cursor: "pointer",
              }}>
              +
            </span>
          </div>
        </span>
      </div> */}
      {activeButton === "payoffchart" && (
        // Show the graph when activeButton is "payoffchart"
        <div id="container">
          {chartError ? (
            <div>Error rendering chart: {chartError.message}</div>
          ) : (
            <HighchartsReact
              highcharts={Highcharts}
              allowChartUpdate={true}
              options={linechart}
              ref={chartComponent}
            />
          )}
        </div>
      )}
      {activeButton === "p&ltable" && (
        // Show the table when activeButton is "p&ltable"
        <div
          className="main-graph-table table-container my-3"
          // Note: The style prop below will make the table visible when activeButton is "p&ltable"
          style={{ display: activeButton === "p&ltable" ? "block" : "none" }}>
          <table className="strategy-tablee">
            <thead>
              <tr
                className="text-center tr-strategy-headerr"
                style={{ border: "1px solid #EFEFEF" }}>
                <th scope="col" className="text-start ps-3 pe-5 py-2 ">
                  Instrument
                </th>
                <th scope="col">Lots</th>
                <th scope="col">Entry price</th>
                <th scope="col">Target price</th>
                <th scope="col" className="pe-3">
                  Estimated P&L
                </th>
              </tr>
            </thead>
            <tbody className="tbody">
              {selectedData &&
                selectedData.map((obj) => (
                  <tr className="strategy-table-roww text-center">
                    <td className="table-cell text-start ps-3">
                      <div className="d-flex align-items-center">
                        {/* BANKNIFTY 15 JUN 23 42100 CE */}
                        {obj?.tradingSymbol}
                      </div>
                    </td>
                    <td className="table-cell">{obj?.lotSize}</td>

                    <td className="table-cell">94.1</td>
                    <td className="table-cell">32.6</td>
                    <td className="table-cell pe-3 text-success">₹ 1,537.50</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="d-flex justify-content-end align-items-center pe-3 mt-5">
            <span className="graph-text">Total estimated P&L </span>
            <svg
              className="ms-2 me-3"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none">
              <path
                d="M7.00004 8.1667V7.71721C7.00004 7.13791 7.44495 6.65974 7.94111 6.36071C8.42012 6.07203 8.8775 5.55888 8.75004 4.66667C8.50004 2.91666 6.12504 3.79168 6.12504 3.79168M7.00004 9.91668V10.2083M13.4167 7.00001C13.4167 10.5438 10.5439 13.4167 7.00004 13.4167C3.45621 13.4167 0.583374 10.5438 0.583374 7.00001C0.583374 3.45618 3.45621 0.583344 7.00004 0.583344C10.5439 0.583344 13.4167 3.45618 13.4167 7.00001Z"
                stroke="#555555"
                strokeLinecap="round"
                strokeLinejoin="round"
              />{" "}
            </svg>
            <span className="estimated-total">₹ 3,035.00 (13.00%)</span>
          </div>
        </div>
      )}
      <div
        className="d-flex justify-content-between align-items-center mt-4
       pt-3">
        {/* <div className="d-flex justify-content-between align-items-center gap-3">
          <span className="graph-text">Spot price</span>
          <div style={{ position: "relative" }}>
            <input
              type="range"
              name=""
              id="custom-range"
              min={lowerLimit}
              max={upperLimit}
              value={sliderValue}
              onChange={handleSliderChange}
            />
            <div
              className="graph-footer-1"
              style={{
                left: `calc(${left}% - 40px)`,
              }}>
              <span>{sliderValue}</span>
              <div className="graph-footer-2" />
            </div>
          </div>

          <span className="iv d-flex justify-content-center align-items-center mt-1">
            {diff}
          </span>
        </div> */}
        {/* <div className="d-flex justify-content-between align-items-center gap-4">
          <span className="graph-text">Target day</span>
          <div className="dropdown">
            <button
              className="btn btn-date dropdown-toggle"
              type="button"
              style={{ width: "120px" }}
              data-bs-toggle="dropdown"
              aria-expanded="false">
              {selectedDate?.toDateString()}
            </button>
            <ul className="dropdown-menu">
              {expiries?.map((o, ind) => (
                <li className="dropdown-item" onClick={handleDateClick}>
                  {o?.toDateString()}
                </li>
              ))}
            </ul>
          </div>
          <span className="graph-text">
            {daysleft} day{daysleft > 1 ? "s" : ""} to expire
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default AnalyseGraph;
