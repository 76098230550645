import React from "react";

const SymbolInfo = ({ symbol, symbolData }) => (
  <div className="symbol-containers-mobile d-flex gap-1 align-items-baseline me-3">
    <div className="symbol-name">{symbol.name}</div>
    <div className="d-flex gap-1">
      <span className={symbol.class}>
        {symbolData[symbol.id]?.ltp.split(".")[0]}.
        <span style={{ fontWeight: 400 }}>
          {symbolData[symbol.id]?.ltp.split(".")[1]}
        </span>
      </span>
      <span
        className={`ch-chp me-2 ${
          symbolData[symbol.id]?.change > 0 ? "text-success" : "text-danger"
        }`}>
        {symbolData[symbol.id]?.change > 0 ? "+" : ""}
        {symbolData[symbol.id]?.change}
        {symbolData[symbol.id]?.change_per ? "(" : ""}
        {symbolData[symbol.id]?.change_per}
        {symbolData[symbol.id]?.change_per ? "%)" : "-"}
      </span>
    </div>
  </div>
);

const Banner = ({ symbolData, symbols, speed = 5000 }) => (
  <div className="inner">
    <div className="wrapper">
      {symbols.map(({ id, image }) => (
        <section key={id} style={{ "--speed": `${speed}ms` }}>
          {[
            { id: "26000", name: "NIFTY", class: "nifty" },
            { id: "26009", name: "BANKNIFTY", class: "banknifty" },
            { id: "26017", name: "INDIA VIX", class: "indiavix" },
          ].map((symbol) => (
            <SymbolInfo
              key={symbol.id}
              symbol={symbol}
              symbolData={symbolData}
            />
          ))}
        </section>
      ))}
    </div>
  </div>
);

export default Banner;
