import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { WebSocketProvider } from "./context/WebSocketContext";
import { ABWebSocketProvider } from "./context/ABWebsocketContext";
import { FyersProvider } from "./context/FyersContext";
import { OptionChainProvider } from "./context/OptionChainContext";
import { LtpProvider } from "./context/LtpContext";
import { BasketProvider } from "./context/BasketContext";
import { GraphProvider } from "./context/GraphContext";
import { VisibilityProvider } from "./context/VisibilityContext ";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <HelmetProvider>
      <ABWebSocketProvider>
        <GraphProvider>
          <BasketProvider>
            <LtpProvider>
              <FyersProvider>
                <OptionChainProvider>
                  <VisibilityProvider>
                    <App />
                  </VisibilityProvider>
                </OptionChainProvider>
              </FyersProvider>
            </LtpProvider>
          </BasketProvider>
        </GraphProvider>
      </ABWebSocketProvider>
    </HelmetProvider>
  </BrowserRouter>
);

reportWebVitals();
