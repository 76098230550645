import React from "react";
import OptionChainMobile from "./OptionChainMobile";
import OptionChainDesktop from "./OptionChainDesktop";
import { Helmet } from "react-helmet-async";

const OptionChain = () => {

  function isMobile() {
    return (window.screen.width > 1280) ? false : true;
  }

  return isMobile() ?   (
    <>
      <Helmet>
        <title>Option Chain</title>
      </Helmet>
      <div className="mobile-screen d-grid" style={{ gridColumn: "span 4" }}>
        <OptionChainMobile />
      </div>
    </>
  ) : (
    <>
      <Helmet>
        <title>Option Chain</title>
      </Helmet>
      <div>
        <OptionChainDesktop />
      </div>
    </>
  ) ;
};

export default OptionChain;
