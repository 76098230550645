import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import { Helmet } from "react-helmet-async";
import shield from "../../images/shield.svg";
import pencil from "../../images/pencil.svg";
import questionmark from "../../images/questionmark.svg";
import PositionsTable from "../../components/PositionsTable";
import axios, { all } from "axios";
import BuySellModal from "../../modals/BuySellModal";
import ABWebSocketContext from "../../context/ABWebsocketContext";
import { getSymbols } from "../../utils/api";
import { useVisibility } from "../../context/VisibilityContext ";
import AnalyseMobile from "../../modals/AnalyseMobile";
import OptionChainMobile from "../../screens/OptionChain/OptionChainMobile";
import { useNavigate } from "react-router-dom";

const access_token = localStorage.getItem("access_token");
let triggerSubscribe = true;
let connectionEstablised = false;

const PositionsMobile = () => {
  const [activeButton, setActiveButton] = useState("groupbyunderlying");
  // This useEffect will be executed once when the component mounts
  const [metrics, setMetrics] = useState({
    total_positions: 0,
    open_positions: 0,
  });
  const [nsesubscribeSymbols, setNSESubscribeSymbols] = useState({});
  const [nfosubscribeSymbols, setNFOSubscribeSymbols] = useState({});
  const [allPositions, setAllPositions] = useState([]);
  const [strategyBased, setStrategyBased] = useState([]);
  const [underlyingBased, setUnderlyingBased] = useState([]);
  const [symbols, setSymbols] = useState([]);

  const [netProfit, setNetProfit] = useState(0);
  const [potentialProfit, setPotentialProfit] = useState(0);
  const [potentialLoss, setPotentialLoss] = useState(0);
  const [pendingProfit, setPendingProfit] = useState(0);
  const [achievedPercent, setAchievedPercent] = useState(0);
  const [selectedOption, setSelectedOption] = useState("buy");

  const handleBuyButtonClick = () => {
    setSelectedOption("buy");
  };

  const handleSellButtonClick = () => {
    setSelectedOption("sell");
  };

  let totalMaxProfit = 0;
  let totalMaxLoss = 0;

  const {
    updateSymbolList,
    websocketConnection,
    checkWebsocketConnection,
    subscribeToSymbols,
    unsubscribeToSymbols,
    symbolList,
    symbolData,
  } = useContext(ABWebSocketContext);

  useEffect(() => {
    const apiEndpoint = `https://dryeadhptf.execute-api.ap-south-1.amazonaws.com/default/optiongeeks_fyers_positions`;
    const body = {
      access_token: access_token,
    };
    const fetchPositions = async () => {
      try {
        const response = await axios.post(apiEndpoint, body);
        if (response.data?.nse_subscribe_symbols) {
          setNSESubscribeSymbols(response.data?.nse_subscribe_symbols);
          setNFOSubscribeSymbols(response.data?.nfo_subscribe_symbols);
          setMetrics(response.data?.metrics);
        }
        setAllPositions(response.data.all_positions);
        const symbols = await getSymbols();
        setSymbols(symbols);
        setStrategyBased(response?.data.groupby_strategy);
        setUnderlyingBased(response?.data.groupby_underlying);
      } catch (error) {
        console.error("Error fetching positions:", error);
      }
    };

    fetchPositions();
  }, []);

  const handleCallback = async ([rowData]) => {};

  const callBackToUpdateAlloption = (newOptions) => {};

  const handlebreakevens = (
    maxProfit,
    maxLoss,
    breakevenRange,
    data,
    type,
    index
  ) => {
    if (type === "symbol") {
      let item = underlyingBased[index];
      item.breakeven = breakevenRange;
      setUnderlyingBased((prevData) => [...prevData]);
    } else if (type === "strategy") {
      let item = strategyBased[index];
      item.breakeven = breakevenRange;
      setStrategyBased((prevData) => [...prevData]);
    }
    // debugger;
    if (maxLoss === "Unlimited") {
      totalMaxLoss = "Unlimited";
      setPotentialLoss(totalMaxLoss);
    }
    if (maxProfit === "Unlimited") {
      totalMaxProfit = "Unlimited";
      setPotentialProfit(totalMaxProfit);
    }

    if (totalMaxLoss !== "Unlimited") {
      totalMaxLoss = totalMaxLoss + maxLoss;
      setPotentialLoss(totalMaxLoss);
    }
    if (totalMaxProfit !== "Unlimited") {
      totalMaxProfit = totalMaxProfit + maxProfit;
      setPotentialProfit(totalMaxProfit);
    }
  };

  useEffect(() => {
    let pendingProfit = 0;
    let totalPnl = 0;
    allPositions?.map((position, index) => {
      let ltp = symbolData[position?.ab_code]?.ltp;
      if (ltp) {
        let pnl =
          (parseFloat(ltp) - parseFloat(position?.average_prc)) *
          parseFloat(position?.net_quantity);
        totalPnl = totalPnl + pnl;
      }
    });
    setNetProfit(totalPnl.toFixed(2));
    if (potentialProfit !== "Unlimited") {
      pendingProfit = potentialProfit - totalPnl;
      setPendingProfit(pendingProfit.toFixed(2));
      setAchievedPercent(((totalPnl / potentialProfit) * 100).toFixed(2) + "%");
    }
  }, [symbolData, allPositions, potentialProfit]);

  useLayoutEffect(() => {
    connectionEstablised = checkWebsocketConnection();
    if (connectionEstablised) {
      if (triggerSubscribe) {
        if (Object.keys(nsesubscribeSymbols).length !== 0) {
          subscribeToSymbols(nsesubscribeSymbols, "NSE");
        }
        if (Object.keys(nfosubscribeSymbols).length !== 0) {
          subscribeToSymbols(nfosubscribeSymbols, "NFO");
        }
        if (
          Object.keys(nfosubscribeSymbols).length !== 0 &&
          Object.keys(nsesubscribeSymbols).length !== 0
        ) {
          triggerSubscribe = false;
        } else {
          triggerSubscribe = true;
        }
      }
    } else {
      triggerSubscribe = true;
    }
  }, [symbolData, triggerSubscribe, nsesubscribeSymbols, nfosubscribeSymbols]);

  const handleButtonClick = (button) => {
    setActiveButton(button);
    setActiveTable(button);
    // Update the exit button enabled status
    setExitButtonEnabled(isAnyCheckboxChecked);
  };

  const [showButtons, setShowButtons] = useState(false);

  const handleMouseEnter = () => {
    setShowButtons(true);
  };

  const handleMouseLeave = () => {
    setShowButtons(false);
  };

  // For tables in watchlis
  const [showTables, setShowTables] = useState(false);
  const handleToggleTables = () => {
    setShowTables((prevShowTables) => !prevShowTables);
    setShowButtons(true);
  };

  const [selectedAllPositions, setSelectedAllPositions] = useState([]);
  const [selectedUnderlying, setSelectedUnderlying] = useState([]);
  const [selectedExecutedStrategies, setSelectedExecutedStrategies] = useState(
    []
  );

  const [
    isExitSelectedEnabledAllPositions,
    setIsExitSelectedEnabledAllPositions,
  ] = useState(false);
  const [isExitSelectedEnabledUnderlying, setIsExitSelectedEnabledUnderlying] =
    useState(false);
  const [
    isExitSelectedEnabledExecutedStrategies,
    setIsExitSelectedEnabledExecutedStrategies,
  ] = useState(false);

  const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(false);

  const [exitButtonEnabled, setExitButtonEnabled] = useState(false);

  // Define state variables for each table
  const [isAllPositionsButtonsVisible, setIsAllPositionsButtonsVisible] =
    useState(false);
  const [isUnderlyingButtonsVisible, setIsUnderlyingButtonsVisible] =
    useState(false);
  const [
    isExecutedStrategiesButtonsVisible,
    setIsExecutedStrategiesButtonsVisible,
  ] = useState(false);

  // Function to check and set the visibility of buttons for each table
  const checkCheckboxStatus = () => {
    const isAllPositionsChecked = selectedAllPositions.some(
      (isSelected) => isSelected
    );
    const isUnderlyingChecked = selectedUnderlying.some(
      (isSelected) => isSelected
    );
    const isExecutedStrategiesChecked = selectedExecutedStrategies.some(
      (isSelected) => isSelected
    );

    setIsAllPositionsButtonsVisible(isAllPositionsChecked);
    setIsUnderlyingButtonsVisible(isUnderlyingChecked);
    setIsExecutedStrategiesButtonsVisible(isExecutedStrategiesChecked);
  };

  const handleCheckboxChange = (table, index) => {
    switch (table) {
      case "allpositions":
        const allPositionsSelected = [...selectedAllPositions];
        allPositionsSelected[index] = !allPositionsSelected[index];
        setSelectedAllPositions(allPositionsSelected);
        setIsExitSelectedEnabledAllPositions(
          allPositionsSelected.some((isSelected) => isSelected)
        );
        break;
      case "groupbyunderlying":
        const underlyingSelected = [...selectedUnderlying];
        underlyingSelected[index] = !underlyingSelected[index];
        setSelectedUnderlying(underlyingSelected);
        setIsExitSelectedEnabledUnderlying(
          underlyingSelected.some((isSelected) => isSelected)
        );
        break;
      case "executedstrategies":
        const executedStrategiesSelected = [...selectedExecutedStrategies];
        executedStrategiesSelected[index] = !executedStrategiesSelected[index];
        setSelectedExecutedStrategies(executedStrategiesSelected);
        setIsExitSelectedEnabledExecutedStrategies(
          executedStrategiesSelected.some((isSelected) => isSelected)
        );
        break;
      default:
        break;
    }

    checkCheckboxStatus();
  };
  useEffect(() => {
    // Checking the checkbox status
    checkCheckboxStatus();
  }, [selectedAllPositions, selectedUnderlying, selectedExecutedStrategies]);

  const handleExitSelected = (table) => {
    switch (table) {
      case "allpositions":
        setSelectedAllPositions([]);
        setIsExitSelectedEnabledAllPositions(false);
        break;
      case "groupbyunderlying":
        setSelectedUnderlying([]);
        setIsExitSelectedEnabledUnderlying(false);
        break;
      case "executedstrategies":
        setSelectedExecutedStrategies([]);
        setIsExitSelectedEnabledExecutedStrategies(false);
        break;
      default:
        break;
    }
  };

  const [activeTable, setActiveTable] = useState("allpositions");

  function formatNumberWithFontWeight(number) {
    const formattedValue = parseFloat(number).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const parts = formattedValue.split(".");
    const formattedFraction = (
      <span style={{ fontWeight: 400 }}>{parts[1]}</span>
    );

    return (
      <span>
        {parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}.{""}
        {formattedFraction}
      </span>
    );
  }
  const { setButtonsVisibility } = useVisibility();
  const areButtonsVisible =
    isAllPositionsButtonsVisible ||
    isUnderlyingButtonsVisible ||
    isExecutedStrategiesButtonsVisible;
  setButtonsVisibility(areButtonsVisible);

  const [isBasketOpen, setIsBasketOpen] = useState(false);
  const handleOpenBasket = () => {
    setIsBasketOpen(true);
  };

  const handleCloseBasket = () => {
    setIsBasketOpen(false);
  };
  const navigate = useNavigate();

  const redirectToOptionChain = (symbol) => {
    navigate(`/optionchain`);
  };
  return (
    <>
      <Helmet>
        <title>Positions</title>
      </Helmet>

      <div className="pos-menu-container strategy-menu d-flex justify-content-start align-items-center gap-4 flex-wrap mt-3 py-1">
        <div className="d-flex flex-column">
          <span className="creator-bottom-title">Net P&L</span>
          <span
            className="strategy-menu-value"
            style={{ color: netProfit > 0 ? "#5CA81D" : "#DD6565" }}>
            {formatNumberWithFontWeight(netProfit)}
          </span>
        </div>

        {/* <div className="d-flex flex-column">
            <span className="creator-bottom-title">Today's P&L</span>
            <span className="strategy-menu-value text-success">3142.93</span>
          </div> */}
        <div className="d-flex flex-column" style={{ marginRight: "18px" }}>
          <span className="creator-bottom-title">Open / Total positions </span>
          <span className="strategy-menu-value">
            {metrics?.total_positions} {"/ "}
            {metrics?.open_positions}
          </span>
        </div>

        <div className="d-flex flex-column">
          <span className="creator-bottom-title">
            Potential profit <img src={questionmark} alt="" />
          </span>
          <span className="strategy-menu-value">
            {formatNumberWithFontWeight(pendingProfit)}
          </span>
        </div>

        <div className="d-flex flex-column">
          <span className="creator-bottom-title">
            Potential loss <img src={questionmark} alt="" />
          </span>
          <span className="strategy-menu-value">
            {potentialLoss === "Unlimited"
              ? potentialLoss
              : String(potentialLoss).split(".")[0]}
            {potentialLoss === "Unlimited" ? null : "."}
            <span style={{ fontWeight: 400 }}>
              {potentialLoss === "Unlimited"
                ? null
                : String(potentialLoss).split(".")[1]}
            </span>
          </span>
        </div>

        <div className="d-flex flex-column">
          <span className="creator-bottom-title">
            Achieved <img src={questionmark} alt="" />
          </span>
          <span className="strategy-menu-value">
            {String(achievedPercent).split(".")[0]}.
            <span style={{ fontWeight: 400 }}>
              {String(achievedPercent).split(".")[1]}
            </span>
          </span>
        </div>
      </div>
      <div className="graph-btns d-flex gap-2" style={{ marginTop: "12px" }}>
        <button
          className={`filter-btns ${
            activeButton === "allpositions" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("allpositions")}>
          All
        </button>
        <button
          className={`filter-btns ${
            activeButton === "groupbyunderlying" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("groupbyunderlying")}>
          Group by underlying
        </button>
        <button
          className={`filter-btns ${
            activeButton === "executedstrategies" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("executedstrategies")}>
          Executed strategies
        </button>
      </div>
      {activeButton == "allpositions" &&
        allPositions &&
        allPositions.length > 0 && (
          <div className="positions-table" style={{ marginTop: "12px" }}>
            <div className="position-container pos-table-style">
              <table className="position-table  w-100">
                <thead>
                  <tr className="text-center tr-strategy-headerr position-table-header ">
                    <tr
                      className="d-flex justify-content-start align-items-center px-2 gap-2"
                      style={{ width: "90vw" }}>
                      <th scope="col d-flex align-itmes-center justify-content-center">
                        <input
                          type="checkbox"
                          className="d-flex align-itmes-center justify-content-center"
                        />
                      </th>
                      <th scope="col" className="text-start">
                        Instruments
                      </th>

                      <th
                        scope="col"
                        style={{ marginLeft: "auto", marginRight: "12px" }}>
                        Product
                      </th>
                      <th scope="col">Qty</th>
                    </tr>

                    <tr
                      className="d-flex justify-content-between align-items-center px-2"
                      style={{ width: "90vw" }}>
                      <th scope="col" style={{ marginLeft: "24px" }}>
                        Avg price
                      </th>
                      <th scope="col">LTP</th>
                      <th scope="col"> P&L</th>
                    </tr>
                  </tr>
                </thead>
                <tbody>
                  {allPositions.map((position, index) => (
                    <tr className="position-table-row text-center">
                      <tr
                        className="d-flex  align-items-center justify-content-start px-2 pb-2 gap-2"
                        style={{ width: "90vw" }}>
                        <td className="d-flex align-items-center justify-content-center">
                          <input
                            type="checkbox"
                            className="d-flex align-items-center justify-content-center"
                            checked={selectedAllPositions[index] || false}
                            onChange={() =>
                              handleCheckboxChange("allpositions", index)
                            }
                          />
                        </td>
                        <td className="table-data text-start">
                          {position.instrument_name}
                        </td>

                        <td
                          className="d-flex align-items-center justify-content-center my-1"
                          style={{
                            marginLeft: "auto",
                            backgroundColor:
                              position.product_type === "Normal"
                                ? "#DBDCFF"
                                : "#FFEDCB",
                            color:
                              position.product_type === "Normal"
                                ? "#383FEC"
                                : "#E2A838",
                          }}>
                          <div className="product-type text-center d-flex align-items-center justify-content-center">
                            {position.product_type}
                          </div>
                        </td>

                        <td
                          className="table-data"
                          style={{
                            color:
                              parseFloat(position.net_quantity) > 0
                                ? "#5CA81D"
                                : "#DD6565",
                          }}>
                          {position.net_quantity}
                        </td>
                      </tr>
                      <tr
                        className="d-flex  align-items-center justify-content-between px-2 pb-2"
                        style={{ width: "90vw" }}>
                        <td
                          className="table-data"
                          style={{ marginLeft: "30px" }}>
                          {String(position.average_prc).split(".")[0]}.
                          <span style={{ fontWeight: 400 }}>
                            {String(position.average_prc).split(".")[1]}
                          </span>
                        </td>
                        <td className="table-data ps-5">
                          {
                            String(symbolData[position.ab_code]?.ltp).split(
                              "."
                            )[0]
                          }
                          .
                          <span style={{ fontWeight: 400 }}>
                            {
                              String(symbolData[position.ab_code]?.ltp).split(
                                "."
                              )[1]
                            }
                          </span>
                        </td>
                        <td
                          className="table-data"
                          style={{
                            color:
                              parseFloat(
                                (symbolData[position.ab_code]?.ltp -
                                  position.average_prc) *
                                  position.net_quantity
                              ) > 0
                                ? "#5CA81D"
                                : "#DD6565",
                          }}>
                          {formatNumberWithFontWeight(
                            (symbolData[position.ab_code]?.ltp -
                              position.average_prc) *
                              position.net_quantity
                          )}
                        </td>
                      </tr>
                    </tr>
                  ))}
                  {/* Group by underlying table */}
                  {activeButton == "groupbyunderlying" &&
                    underlyingBased &&
                    underlyingBased.map((data, index) => (
                      <div className="group-table d-flex flex-column gap-3">
                        <div className="group-container">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="strategy-menu border-0 d-flex justify-content-start align-items-center px-3 gap-3">
                              <div className="d-flex flex-column">
                                <span className="creator-bottom-title">
                                  Symbol{" "}
                                </span>
                                <span className="strategy-menu-value">
                                  {data.scrip}
                                </span>
                              </div>
                              <div className="d-flex flex-column">
                                <span className="creator-bottom-title">
                                  Spot price{" "}
                                </span>
                                <span className="strategy-menu-value">
                                  {
                                    String(symbolData[data.ab_code]?.ltp).split(
                                      "."
                                    )[0]
                                  }
                                  .
                                  <span style={{ fontWeight: 400 }}>
                                    {
                                      String(
                                        symbolData[data.ab_code]?.ltp
                                      ).split(".")[1]
                                    }
                                  </span>
                                </span>
                              </div>
                              <div className="d-flex flex-column">
                                <span className="creator-bottom-title">
                                  Breakeven{" "}
                                </span>
                                <span className="strategy-menu-value">
                                  {data?.breakeven}
                                </span>
                              </div>
                              {/* <div className="d-flex flex-column">
                      <span className="creator-bottom-title">Breakeven </span>
                      <span className="strategy-menu-value">
                        {String(data?.breakeven).split(".")[0]}.
                        <span style={{ fontWeight: 400 }}>
                          {String(data?.breakeven).split(".")[1]}
                        </span>
                      </span>
                    </div> */}
                              <div className="d-flex flex-column">
                                <span className="creator-bottom-title">
                                  Net P&L
                                </span>
                                <span
                                  className="strategy-menu-value"
                                  style={{
                                    color:
                                      netProfit > 0 ? "#5CA81D" : "#DD6565",
                                  }}>
                                  {formatNumberWithFontWeight(netProfit)}
                                </span>
                              </div>
                            </div>
                          </div>
                          <table className="position-table w-100">
                            <thead>
                              <tr className="text-center tr-strategy-headerr position-table-header ">
                                <tr
                                  className="d-flex justify-content-start align-items-center px-2 gap-2"
                                  style={{ width: "90vw" }}>
                                  <th scope="col d-flex align-itmes-center justify-content-center">
                                    <input
                                      type="checkbox"
                                      className="d-flex align-itmes-center justify-content-center"
                                    />
                                  </th>
                                  <th scope="col" className="text-start">
                                    Instruments
                                  </th>

                                  <th
                                    scope="col"
                                    style={{
                                      marginLeft: "auto",
                                      marginRight: "12px",
                                    }}>
                                    Product
                                  </th>
                                  <th scope="col">Qty</th>
                                </tr>

                                <tr
                                  className="d-flex justify-content-between align-items-center px-2"
                                  style={{ width: "90vw" }}>
                                  <th
                                    scope="col"
                                    style={{ marginLeft: "24px" }}>
                                    Avg price
                                  </th>
                                  <th scope="col">LTP</th>
                                  <th scope="col"> P&L</th>
                                </tr>
                              </tr>
                            </thead>
                            <tbody>
                              {data.positions &&
                                data.positions.map((obj, index) => (
                                  <tr className="position-table-row text-center">
                                    <td className="d-flex align-items-center justify-content-center">
                                      <input
                                        type="checkbox"
                                        className="d-flex align-items-center justify-content-center"
                                        checked={
                                          selectedUnderlying[index] || false
                                        }
                                        onChange={() =>
                                          handleCheckboxChange(
                                            "groupbyunderlying",
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                    <td className="table-data text-start">
                                      {obj.instrument_name}
                                    </td>

                                    <td
                                      className="d-flex align-items-center justify-content-center my-1"
                                      style={{
                                        marginLeft: "auto",
                                        backgroundColor:
                                          obj.product_type === "Normal"
                                            ? "#DBDCFF"
                                            : "#FFEDCB",
                                        color:
                                          obj.product_type === "Normal"
                                            ? "#383FEC"
                                            : "#E2A838",
                                      }}>
                                      <div className="product-type text-center d-flex align-items-center justify-content-center">
                                        {obj.product_type}
                                      </div>
                                    </td>

                                    <td
                                      className="table-data"
                                      style={{
                                        color:
                                          parseFloat(obj.net_quantity) > 0
                                            ? "#5CA81D"
                                            : "#DD6565",
                                      }}>
                                      {obj.net_quantity}
                                    </td>
                                    <td className="table-data">
                                      {String(obj.average_prc).split(".")[0]}.
                                      <span style={{ fontWeight: 400 }}>
                                        {String(obj.average_prc).split(".")[1]}
                                      </span>
                                    </td>
                                    <td className="table-data">
                                      {
                                        String(
                                          symbolData[obj.ab_code]?.ltp
                                        ).split(".")[0]
                                      }
                                      .
                                      <span style={{ fontWeight: 400 }}>
                                        {
                                          String(
                                            symbolData[obj.ab_code]?.ltp
                                          ).split(".")[1]
                                        }
                                      </span>
                                    </td>
                                    <td
                                      className="table-data"
                                      style={{
                                        color:
                                          parseFloat(
                                            (symbolData[obj.ab_code]?.ltp -
                                              obj.average_prc) *
                                              obj.net_quantity
                                          ) > 0
                                            ? "#5CA81D"
                                            : "#DD6565",
                                      }}>
                                      {formatNumberWithFontWeight(
                                        (symbolData[obj.ab_code]?.ltp -
                                          obj.average_prc) *
                                          obj.net_quantity
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

      {/* Group by underlying table */}
      {activeButton == "groupbyunderlying" &&
        underlyingBased &&
        underlyingBased.map((data, index) => (
          <div
            className="group-table d-flex flex-column gap-3"
            style={{ marginTop: "12px" }}>
            <div className="group-container">
              <div className="d-flex flex-column justify-content-center align-items-start py-2 gap-2">
                <div
                  className="border-0 d-flex justify-content-between align-items-center px-3 gap-4"
                  style={{ width: "-webkit-fill-available" }}>
                  <div className="d-flex flex-column">
                    <span className="creator-bottom-title">Symbol </span>
                    <span className="strategy-menu-value">{data.scrip}</span>
                  </div>
                  <div className="d-flex flex-column">
                    <span className="creator-bottom-title">Spot </span>
                    <span className="strategy-menu-value">
                      {String(symbolData[data.ab_code]?.ltp).split(".")[0]}.
                      <span style={{ fontWeight: 400 }}>
                        {String(symbolData[data.ab_code]?.ltp).split(".")[1]}
                      </span>
                    </span>
                  </div>
                  <div className="d-flex flex-column">
                    <span className="creator-bottom-title">Net P&L</span>
                    <span
                      className="strategy-menu-value"
                      style={{
                        color: netProfit > 0 ? "#5CA81D" : "#DD6565",
                      }}>
                      {formatNumberWithFontWeight(netProfit)}
                    </span>
                  </div>
                  <div className="d-flex flex-column pos-table-more dropdown">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="11"
                      viewBox="0 0 16 11"
                      fill="none"
                      className="dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <path
                        d="M1 1H15M1 5.5H15M1 10H15"
                        stroke="#14142B"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className="strategy-table-dropdown dropdown-menu  py-0">
                      <li className="dropdown-item px-2">
                        <button
                          className="create-strategy-btn"
                          onClick={redirectToOptionChain}>
                          Option Chain
                        </button>
                      </li>
                      <li className="dropdown-item px-2">
                        <button
                          className="create-strategy-btn analyse-op"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropAnalyseMobile">
                          <img src={shield} alt="" />
                          Analyse
                        </button>
                      </li>
                    </div>
                    <AnalyseMobile
                      parentCallback2={handlebreakevens}
                      selectedData={data.positions}
                      selectedSymbol={
                        symbols?.filter(
                          (option) => option.scrip === data.scrip
                        )[0]
                      }
                      type="symbol"
                      index={index}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column px-3">
                  <span className="creator-bottom-title">Breakeven </span>
                  <span className="strategy-menu-value">{data?.breakeven}</span>
                </div>
              </div>

              <table className="position-table w-100">
                <thead>
                  <tr className="text-center tr-strategy-headerr position-table-header ">
                    <tr
                      className="d-flex justify-content-start align-items-center px-2 gap-2"
                      style={{ width: "90vw" }}>
                      <th scope="col d-flex align-itmes-center justify-content-center">
                        <input
                          type="checkbox"
                          className="d-flex align-itmes-center justify-content-center"
                        />
                      </th>
                      <th scope="col" className="text-start">
                        Instruments
                      </th>

                      <th
                        scope="col"
                        style={{ marginLeft: "auto", marginRight: "12px" }}>
                        Product
                      </th>
                      <th scope="col">Qty</th>
                    </tr>

                    <tr
                      className="d-flex justify-content-between align-items-center px-2"
                      style={{ width: "90vw" }}>
                      <th scope="col" style={{ marginLeft: "24px" }}>
                        Avg price
                      </th>
                      <th scope="col">LTP</th>
                      <th scope="col"> P&L</th>
                    </tr>
                  </tr>
                </thead>
                <tbody>
                  {data.positions &&
                    data.positions.map((obj, index) => (
                      <tr className="position-table-row text-center">
                        <tr
                          className="d-flex  align-items-center justify-content-start px-2 pb-2 gap-2"
                          style={{ width: "90vw" }}>
                          <td className="d-flex align-items-center justify-content-center">
                            <input
                              type="checkbox"
                              className="d-flex align-items-center justify-content-center"
                              checked={selectedUnderlying[index] || false}
                              onChange={() =>
                                handleCheckboxChange("groupbyunderlying", index)
                              }
                            />
                          </td>
                          <td className="table-data text-start">
                            {obj.instrument_name}
                          </td>

                          <td
                            className="d-flex align-items-center justify-content-center my-1"
                            style={{
                              marginLeft: "auto",
                              backgroundColor:
                                obj.product_type === "Normal"
                                  ? "#DBDCFF"
                                  : "#FFEDCB",
                              color:
                                obj.product_type === "Normal"
                                  ? "#383FEC"
                                  : "#E2A838",
                            }}>
                            <div className="product-type text-center d-flex align-items-center justify-content-center">
                              {obj.product_type}
                            </div>
                          </td>

                          <td
                            className="table-data"
                            style={{
                              color:
                                parseFloat(obj.net_quantity) > 0
                                  ? "#5CA81D"
                                  : "#DD6565",
                            }}>
                            {obj.net_quantity}
                          </td>
                        </tr>
                        <tr
                          className="d-flex  align-items-center justify-content-between px-2 pb-2"
                          style={{ width: "90vw" }}>
                          <td
                            className="table-data"
                            style={{ marginLeft: "30px" }}>
                            {String(obj.average_prc).split(".")[0]}.
                            <span style={{ fontWeight: 400 }}>
                              {String(obj.average_prc).split(".")[1]}
                            </span>
                          </td>
                          <td className="table-data">
                            {String(symbolData[obj.ab_code]?.ltp).split(".")[0]}
                            .
                            <span style={{ fontWeight: 400 }}>
                              {
                                String(symbolData[obj.ab_code]?.ltp).split(
                                  "."
                                )[1]
                              }
                            </span>
                          </td>
                          <td
                            className="table-data"
                            style={{
                              color:
                                parseFloat(
                                  (symbolData[obj.ab_code]?.ltp -
                                    obj.average_prc) *
                                    obj.net_quantity
                                ) > 0
                                  ? "#5CA81D"
                                  : "#DD6565",
                            }}>
                            {formatNumberWithFontWeight(
                              (symbolData[obj.ab_code]?.ltp - obj.average_prc) *
                                obj.net_quantity
                            )}
                          </td>
                        </tr>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}

      {/* Executed strategy table */}
      {activeButton === "executedstrategies" &&
        strategyBased &&
        strategyBased.map((data, index) => (
          <div
            className="executed-table group-table d-flex flex-column gap-3"
            style={{ marginTop: "12px" }}>
            <div className="group-container">
              <div className="mobile-strategy-name fw-bold px-3 py-1">
                {data.strategy}
              </div>
              <div className="d-flex flex-column justify-content-center align-items-start py-2 gap-2">
                <div
                  className="border-0 d-flex justify-content-between align-items-center px-3 gap-4"
                  style={{ width: "-webkit-fill-available" }}>
                  <div className="d-flex flex-column">
                    <span className="creator-bottom-title">Symbol </span>
                    <span className="strategy-menu-value">{data.scrip}</span>
                  </div>
                  <div className="d-flex flex-column">
                    <span className="creator-bottom-title">Spot </span>
                    <span className="strategy-menu-value">
                      {String(symbolData[data.ab_code]?.ltp).split(".")[0]}.
                      <span style={{ fontWeight: 400 }}>
                        {String(symbolData[data.ab_code]?.ltp).split(".")[1]}
                      </span>
                    </span>
                  </div>
                  <div className="d-flex flex-column">
                    <span className="creator-bottom-title">Net P&L</span>
                    <span
                      className="strategy-menu-value"
                      style={{
                        color: netProfit > 0 ? "#5CA81D" : "#DD6565",
                      }}>
                      {formatNumberWithFontWeight(netProfit)}
                    </span>
                  </div>
                  <div className="d-flex flex-column pos-table-more dropdown">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="11"
                      viewBox="0 0 16 11"
                      fill="none"
                      className="dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <path
                        d="M1 1H15M1 5.5H15M1 10H15"
                        stroke="#14142B"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className="strategy-table-dropdown dropdown-menu  py-0">
                      <li className="dropdown-item px-2">
                        <button
                          className="create-strategy-btn"
                          onClick={redirectToOptionChain}>
                          Option Chain
                        </button>
                      </li>
                      <li className="dropdown-item px-2">
                        <button
                          className="create-strategy-btn analyse-op"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropAnalyseMobile">
                          <img src={shield} alt="" />
                          Analyse
                        </button>
                      </li>
                    </div>
                    <AnalyseMobile
                      parentCallback2={handlebreakevens}
                      selectedData={data.positions}
                      selectedSymbol={
                        symbols?.filter(
                          (option) => option.scrip === data.scrip
                        )[0]
                      }
                      type="strategy"
                      index={index}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column px-3">
                  <span className="creator-bottom-title">Breakeven </span>
                  <span className="strategy-menu-value">{data?.breakeven}</span>
                </div>
              </div>
              <table className="position-table w-100">
                <thead>
                  <tr className="text-center tr-strategy-headerr position-table-header ">
                    <tr
                      className="d-flex justify-content-start align-items-center px-2 gap-2"
                      style={{ width: "90vw" }}>
                      <th scope="col d-flex align-itmes-center justify-content-center">
                        <input
                          type="checkbox"
                          className="d-flex align-itmes-center justify-content-center"
                        />
                      </th>
                      <th scope="col" className="text-start">
                        Instruments
                      </th>

                      <th
                        scope="col"
                        style={{ marginLeft: "auto", marginRight: "12px" }}>
                        Product
                      </th>
                      <th scope="col">Qty</th>
                    </tr>

                    <tr
                      className="d-flex justify-content-between align-items-center px-2"
                      style={{ width: "90vw" }}>
                      <th scope="col" style={{ marginLeft: "24px" }}>
                        Avg price
                      </th>
                      <th scope="col">LTP</th>
                      <th scope="col"> P&L</th>
                    </tr>
                  </tr>
                </thead>
                <tbody>
                  {allPositions.map((position, index) => (
                    <tr className="position-table-row text-center">
                      <tr
                        className="d-flex  align-items-center justify-content-start px-2 pb-2 gap-2"
                        style={{ width: "90vw" }}>
                        <td className="d-flex align-items-center justify-content-center">
                          <input
                            type="checkbox"
                            className="d-flex align-items-center justify-content-center"
                            checked={selectedExecutedStrategies[index] || false}
                            onChange={() =>
                              handleCheckboxChange("executedstrategies", index)
                            }
                          />
                        </td>
                        <td className="table-data text-start">
                          {position.instrument_name}
                        </td>

                        <td
                          className="d-flex align-items-center justify-content-center my-1"
                          style={{
                            marginLeft: "auto",
                            backgroundColor:
                              position.product_type === "Normal"
                                ? "#DBDCFF"
                                : "#FFEDCB",
                            color:
                              position.product_type === "Normal"
                                ? "#383FEC"
                                : "#E2A838",
                          }}>
                          <div className="product-type text-center d-flex align-items-center justify-content-center">
                            {position.product_type}
                          </div>
                        </td>

                        <td
                          className="table-data"
                          style={{
                            color:
                              parseFloat(position.net_quantity) > 0
                                ? "#5CA81D"
                                : "#DD6565",
                          }}>
                          {position.net_quantity}
                        </td>
                      </tr>
                      <tr
                        className="d-flex  align-items-center justify-content-between px-2 pb-2"
                        style={{ width: "90vw" }}>
                        <td
                          className="table-data"
                          style={{ marginLeft: "30px" }}>
                          {String(position.average_prc).split(".")[0]}.
                          <span style={{ fontWeight: 400 }}>
                            {String(position.average_prc).split(".")[1]}
                          </span>
                        </td>
                        <td className="table-data ps-5">
                          {
                            String(symbolData[position.ab_code]?.ltp).split(
                              "."
                            )[0]
                          }
                          .
                          <span style={{ fontWeight: 400 }}>
                            {
                              String(symbolData[position.ab_code]?.ltp).split(
                                "."
                              )[1]
                            }
                          </span>
                        </td>
                        <td
                          className="table-data"
                          style={{
                            color:
                              parseFloat(
                                (symbolData[position.ab_code]?.ltp -
                                  position.average_prc) *
                                  position.net_quantity
                              ) > 0
                                ? "#5CA81D"
                                : "#DD6565",
                          }}>
                          {formatNumberWithFontWeight(
                            (symbolData[position.ab_code]?.ltp -
                              position.average_prc) *
                              position.net_quantity
                          )}
                        </td>
                      </tr>
                    </tr>
                  ))}
                  {/* Group by underlying table */}
                  {activeButton == "groupbyunderlying" &&
                    underlyingBased &&
                    underlyingBased.map((data, index) => (
                      <div className="group-table d-flex flex-column gap-3">
                        <div className="group-container">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="strategy-menu border-0 d-flex justify-content-start align-items-center px-3 gap-3">
                              <div className="d-flex flex-column">
                                <span className="creator-bottom-title">
                                  Symbol{" "}
                                </span>
                                <span className="strategy-menu-value">
                                  {data.scrip}
                                </span>
                              </div>
                              <div className="d-flex flex-column">
                                <span className="creator-bottom-title">
                                  Spot price{" "}
                                </span>
                                <span className="strategy-menu-value">
                                  {
                                    String(symbolData[data.ab_code]?.ltp).split(
                                      "."
                                    )[0]
                                  }
                                  .
                                  <span style={{ fontWeight: 400 }}>
                                    {
                                      String(
                                        symbolData[data.ab_code]?.ltp
                                      ).split(".")[1]
                                    }
                                  </span>
                                </span>
                              </div>
                              <div className="d-flex flex-column">
                                <span className="creator-bottom-title">
                                  Breakeven{" "}
                                </span>
                                <span className="strategy-menu-value">
                                  {data?.breakeven}
                                </span>
                              </div>
                              {/* <div className="d-flex flex-column">
                      <span className="creator-bottom-title">Breakeven </span>
                      <span className="strategy-menu-value">
                        {String(data?.breakeven).split(".")[0]}.
                        <span style={{ fontWeight: 400 }}>
                          {String(data?.breakeven).split(".")[1]}
                        </span>
                      </span>
                    </div> */}
                              <div className="d-flex flex-column">
                                <span className="creator-bottom-title">
                                  Net P&L
                                </span>
                                <span
                                  className="strategy-menu-value"
                                  style={{
                                    color:
                                      netProfit > 0 ? "#5CA81D" : "#DD6565",
                                  }}>
                                  {formatNumberWithFontWeight(netProfit)}
                                </span>
                              </div>
                            </div>
                          </div>
                          <table className="position-table w-100">
                            <thead>
                              <tr className="text-center tr-strategy-headerr position-table-header ">
                                <tr
                                  className="d-flex justify-content-start align-items-center px-2 gap-2"
                                  style={{ width: "90vw" }}>
                                  <th scope="col d-flex align-itmes-center justify-content-center">
                                    <input
                                      type="checkbox"
                                      className="d-flex align-itmes-center justify-content-center"
                                    />
                                  </th>
                                  <th scope="col" className="text-start">
                                    Instruments
                                  </th>

                                  <th
                                    scope="col"
                                    style={{
                                      marginLeft: "auto",
                                      marginRight: "12px",
                                    }}>
                                    Product
                                  </th>
                                  <th scope="col">Qty</th>
                                </tr>

                                <tr
                                  className="d-flex justify-content-between align-items-center px-2"
                                  style={{ width: "90vw" }}>
                                  <th
                                    scope="col"
                                    style={{ marginLeft: "30px" }}>
                                    Avg price
                                  </th>
                                  <th scope="col">LTP</th>
                                  <th scope="col"> P&L</th>
                                </tr>
                              </tr>
                            </thead>
                            <tbody>
                              {data.positions &&
                                data.positions.map((obj, index) => (
                                  <tr className="position-table-row text-center">
                                    <td className="d-flex align-items-center justify-content-center">
                                      <input
                                        type="checkbox"
                                        className="d-flex align-items-center justify-content-center"
                                        checked={
                                          selectedExecutedStrategies[index] ||
                                          false
                                        }
                                        onChange={() =>
                                          handleCheckboxChange(
                                            "executedstrategies",
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                    <td className="table-data text-start">
                                      {obj.instrument_name}
                                    </td>

                                    <td
                                      className="d-flex align-items-center justify-content-center my-1"
                                      style={{
                                        marginLeft: "auto",
                                        backgroundColor:
                                          obj.product_type === "Normal"
                                            ? "#DBDCFF"
                                            : "#FFEDCB",
                                        color:
                                          obj.product_type === "Normal"
                                            ? "#383FEC"
                                            : "#E2A838",
                                      }}>
                                      <div className="product-type text-center d-flex align-items-center justify-content-center">
                                        {obj.product_type}
                                      </div>
                                    </td>

                                    <td
                                      className="table-data"
                                      style={{
                                        color:
                                          parseFloat(obj.net_quantity) > 0
                                            ? "#5CA81D"
                                            : "#DD6565",
                                      }}>
                                      {obj.net_quantity}
                                    </td>
                                    <td className="table-data">
                                      {String(obj.average_prc).split(".")[0]}.
                                      <span style={{ fontWeight: 400 }}>
                                        {String(obj.average_prc).split(".")[1]}
                                      </span>
                                    </td>
                                    <td className="table-data">
                                      {
                                        String(
                                          symbolData[obj.ab_code]?.ltp
                                        ).split(".")[0]
                                      }
                                      .
                                      <span style={{ fontWeight: 400 }}>
                                        {
                                          String(
                                            symbolData[obj.ab_code]?.ltp
                                          ).split(".")[1]
                                        }
                                      </span>
                                    </td>
                                    <td
                                      className="table-data"
                                      style={{
                                        color:
                                          parseFloat(
                                            (symbolData[obj.ab_code]?.ltp -
                                              obj.average_prc) *
                                              obj.net_quantity
                                          ) > 0
                                            ? "#5CA81D"
                                            : "#DD6565",
                                      }}>
                                      {formatNumberWithFontWeight(
                                        (symbolData[obj.ab_code]?.ltp -
                                          obj.average_prc) *
                                          obj.net_quantity
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      {activeButton === "allpositions" && isAllPositionsButtonsVisible && (
        <div className="shadow-container">
          <div className="pos-check-buttons d-flex justify-content-between px-3 ">
            <button className="pos-button one-click">One-click reverse</button>
            <button
              className="pos-button exit-pos"
              onClick={() => handleExitSelected("allpositions")}>
              Exit Position
            </button>
          </div>
        </div>
      )}
      {activeButton === "groupbyunderlying" && isUnderlyingButtonsVisible && (
        <div className="shadow-container">
          <div className="pos-check-buttons d-flex justify-content-between px-3">
            <button className="pos-button one-click">One-click reverse</button>
            <button
              className="pos-button exit-pos"
              onClick={() => handleExitSelected("groupbyunderlying")}>
              Exit Position
            </button>
          </div>
        </div>
      )}
      {activeButton === "executedstrategies" &&
        isExecutedStrategiesButtonsVisible && (
          <div className="shadow-container">
            <div className="pos-check-buttons d-flex justify-content-between px-3">
              <button className="pos-button one-click">
                One-click reverse
              </button>
              <button
                className="pos-button exit-pos"
                onClick={() => handleExitSelected("executedstrategies")}>
                Exit Position
              </button>
            </div>
          </div>
        )}
    </>
  );
};

export default PositionsMobile;
