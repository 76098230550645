import React, { useState } from "react";
import { useBasketContext } from "../context/BasketContext";
import { useGraphContext } from "../context/GraphContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomToastContent from "../components/CustomToastContent";
import SuccessIcon from "../images/success_icon.svg";
const BasketMobile = ({ isOpen, onClose }) => {
  const [isBuy, setIsBuy] = useState(true);
  const [isCALL, setIsCALL] = useState(true);
  const {
    basketData,
    removeFromBasket,
    buySellStatus,
    toggleBuySellStatus,
    handleIncrement,
    handleDecrement,
    clearBasket,
    handlePriceChange,
  } = useBasketContext();
  const { maxProfit, maxLoss, riskReward, breakevenRange, premium } =
    useGraphContext();
  const [activeOption, setActiveOption] = useState("intraday");
  const handleOptionChange = (option) => {
    setActiveOption(option);
  };

  // MARKET Checkbox logic
  const [checkboxStates, setCheckboxStates] = useState(
    Array(basketData.length).fill(false)
  );

  //checkbox fun
  const handleCheckboxChange = (index) => {
    const updatedStates = [...checkboxStates];
    updatedStates[index] = !updatedStates[index];
    setCheckboxStates(updatedStates);
  };

  const StrategyLots = ({ row, index, handleIncrement, handleDecrement }) => {
    return (
      <div className="strategy-lots d-flex align-items-center justify-content-between px-2">
        <span
          role="button"
          onClick={() => handleDecrement(index)}
          style={{
            color: "#C1C1C1",
            fontSize: "20px",
            fontWeight: "500",
            lineHeight: "0px",
          }}>
          -
        </span>
        <span>{row?.lotSize}</span>
        <span
          role="button"
          className="plus"
          onClick={() => handleIncrement(index)}
          style={{
            color: "#C1C1C1",
            fontSize: "20px",
            fontWeight: "500",
            lineHeight: "0px",
          }}>
          +
        </span>
      </div>
    );
  };

  const handleExecuteOrder = () => {
    onClose();
    clearBasket();
    toast.success(
      <CustomToastContent
        heading="Order placed successfully!"
        message="Broker API is temporarily disconnected for testing!"
      />,
      {
        className: "custom-success-toast",
        icon: <img src={SuccessIcon} alt="Success Icon" />,
      }
    );
  };
  return (
    <>
      {isOpen && (
        <>
          <div className="basket-mobile-overlay"></div>
          <div className={`basket-mobile ${isOpen ? "show" : ""}`}>
            <div className="d-flex flex-column gap-3 ">
              <div className="d-flex flex-column gap-2 mt-3">
                <div className=" d-flex justify-content-start align-items-center px-3 gap-4 w-100">
                  <div className="d-flex flex-column">
                    <span className="creator-bottom-title">Max profit</span>
                    <span className="strategy-menu-value text-success">
                      {maxProfit}
                    </span>
                  </div>
                  <div className="d-flex flex-column">
                    <span className="creator-bottom-title">Max loss </span>
                    <span className="strategy-menu-value text-danger">
                      {maxLoss}
                    </span>
                  </div>
                  <div className="d-flex flex-column">
                    <span className="creator-bottom-title">Risk reward </span>
                    <span className="strategy-menu-value">{riskReward}</span>
                  </div>
                  <button
                    type="button"
                    className="btn-close ms-auto"
                    onClick={() => {
                      onClose();
                    }}></button>
                </div>
                <div className=" d-flex justify-content-start align-items-center px-3 gap-4 w-100">
                  <div className="d-flex flex-column ">
                    <span className="creator-bottom-title">
                      Breakeven between
                    </span>
                    <span className="strategy-menu-value">
                      {breakevenRange}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="px-0"
                style={{ maxHeight: "360px", overflowY: "auto" }}>
                <div className="table-container">
                  <table className="strategy-tablee">
                    <thead>
                      <tr
                        className="text-center tr-strategy-headerr"
                        style={{ border: "1px solid #EFEFEF" }}>
                        <tr
                          className="d-flex justify-content-start align-items-center gap-3"
                          style={{ width: "98vw", padding: "0px 12px" }}>
                          <th scope="col" className="py-2">
                            B/S
                          </th>
                          <th scope="col" className="py-2">
                            Instrument
                          </th>
                          <th scope="col" className="py-2"></th>
                        </tr>
                        <tr
                          className="d-flex justify-content-between align-items-center "
                          style={{ width: "98vw", padding: "0px 12px" }}>
                          <th scope="col" className="pb-2">
                            Lots
                          </th>
                          <th scope="col">Product type</th>
                          <th scope="col">Price</th>
                        </tr>
                      </tr>
                    </thead>
                  </table>
                  <div style={{ overflowY: "auto", maxHeight: "300px" }}>
                    <table>
                      <tbody className="tbody">
                        {basketData.map((item, index) => (
                          <tr
                            key={index}
                            className="strategy-table-roww border-bottom"
                            style={{ height: "66px" }}>
                            <tr
                              className="d-flex  align-items-center justify-content-start px-2 pb-2"
                              style={{ width: "98vw", padding: "0px 12px" }}>
                              <td className="basket-table-cell text-center">
                                <div
                                  className={`bs-value d-flex align-items-center justify-content-center ${
                                    item["buy_sell"] === "B" ? "buy" : "sell"
                                  }`}
                                  style={{
                                    height: "24px",
                                    width: "24px",
                                    borderRadius: "4px",

                                    background:
                                      item["buy_sell"] === "B"
                                        ? "#70D11F"
                                        : "#FF3908",
                                    color: "white",
                                  }}
                                  onClick={() => toggleBuySellStatus(index)}
                                  role="button">
                                  {item["buy_sell"] === "B" ? "B" : "S"}
                                </div>
                              </td>
                              <td className="basket-table-cell text-start ">
                                <div className="d-flex align-items-center">
                                  {item.instrumentName}
                                </div>
                              </td>
                              <td className="basket-table-action basket-table-cell d-flex align-items-center justify-content-center gap-4 ms-auto">
                                <div className="d-flex align-items-center justify-content-center">
                                  <svg
                                    role="button"
                                    onClick={() => removeFromBasket(index)}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none">
                                    <path
                                      d="M12.6364 3.90909V3.18182C12.6364 1.97683 11.6595 1 10.4545 1H7.54545C6.34047 1 5.36364 1.97683 5.36364 3.18182V3.90909M1 3.90909H17M11.1818 8.27273V11.9091M6.81818 8.27273V11.9091M2.45455 3.90909H15.5455L15.0636 13.5452C14.9669 15.4805 13.3695 17 11.4318 17H6.56818C4.63045 17 3.03312 15.4805 2.93635 13.5452L2.45455 3.90909Z"
                                      stroke="#C1C1C1"
                                      strokeLinecap="round"
                                    />
                                  </svg>
                                </div>
                              </td>
                            </tr>
                            <tr
                              className="d-flex  align-items-center justify-content-between px-2 pb-2"
                              style={{ width: "98vw", padding: "0px 12px" }}>
                              <td className="basket-table-cell">
                                <StrategyLots
                                  row={item}
                                  index={index}
                                  handleIncrement={handleIncrement}
                                  handleDecrement={handleDecrement}
                                />
                              </td>

                              <td className="basket-table-cell">
                                {" "}
                                <div className="toggle-container">
                                  <button
                                    className={`toggle-button ${
                                      activeOption === "intraday"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleOptionChange("intraday")
                                    }>
                                    Intraday
                                  </button>
                                  <button
                                    className={`toggle-button ${
                                      activeOption === "normal" ? "active" : ""
                                    }`}
                                    onClick={() =>
                                      handleOptionChange("normal")
                                    }>
                                    Normal
                                  </button>
                                </div>
                              </td>
                              <td className="basket-table-cell">
                                <div className="price d-flex align-items-center justify-content-between px-2">
                                  <input
                                    type="checkbox"
                                    checked={checkboxStates[index]}
                                    onChange={() => handleCheckboxChange(index)}
                                  />
                                  {checkboxStates[index] ? (
                                    <div style={{ color: "#383FEC" }}>
                                      MARKET
                                    </div>
                                  ) : (
                                    <input
                                      type="text"
                                      value={item.price}
                                      className="price-input pe-1"
                                      onChange={(e) =>
                                        handlePriceChange(e, index)
                                      } // Add onChange handler
                                    />
                                  )}
                                </div>
                              </td>
                            </tr>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                className="creator-bottom d-flex mb-2 gap-2 flex-column border-top "
                style={{ padding: "12px 12px", marginTop: "auto" }}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    <span className="creator-bottom-title">
                      Margin available
                    </span>
                    <span className="creator-bottom-value">₹ 577.00</span>
                  </div>
                  <div className="d-flex">
                    <span className="creator-bottom-title">
                      Charges{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none">
                        <path
                          d="M6.99992 8.16669V7.7172C6.99992 7.1379 7.44483 6.65972 7.94099 6.3607C8.42 6.07201 8.87738 5.55887 8.74992 4.66666C8.49992 2.91665 6.12492 3.79167 6.12492 3.79167M6.99992 9.91667V10.2083M13.4166 7C13.4166 10.5438 10.5437 13.4167 6.99992 13.4167C3.45609 13.4167 0.583252 10.5438 0.583252 7C0.583252 3.45617 3.45609 0.583332 6.99992 0.583332C10.5437 0.583332 13.4166 3.45617 13.4166 7Z"
                          stroke="#555555"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{" "}
                    </span>
                    <span className="creator-bottom-value">-</span>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    <span className="creator-bottom-title">
                      Estmtd margin{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none">
                        <path
                          d="M6.99992 8.16669V7.7172C6.99992 7.1379 7.44483 6.65972 7.94099 6.3607C8.42 6.07201 8.87738 5.55887 8.74992 4.66666C8.49992 2.91665 6.12492 3.79167 6.12492 3.79167M6.99992 9.91667V10.2083M13.4166 7C13.4166 10.5438 10.5437 13.4167 6.99992 13.4167C3.45609 13.4167 0.583252 10.5438 0.583252 7C0.583252 3.45617 3.45609 0.583332 6.99992 0.583332C10.5437 0.583332 13.4166 3.45617 13.4166 7Z"
                          stroke="#555555"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{" "}
                    </span>
                    <span className="creator-bottom-value">-</span>
                  </div>
                  <div className="d-flex">
                    <span className="creator-bottom-title">
                      premium{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none">
                        <path
                          d="M6.99992 8.16669V7.7172C6.99992 7.1379 7.44483 6.65972 7.94099 6.3607C8.42 6.07201 8.87738 5.55887 8.74992 4.66666C8.49992 2.91665 6.12492 3.79167 6.12492 3.79167M6.99992 9.91667V10.2083M13.4166 7C13.4166 10.5438 10.5437 13.4167 6.99992 13.4167C3.45609 13.4167 0.583252 10.5438 0.583252 7C0.583252 3.45617 3.45609 0.583332 6.99992 0.583332C10.5437 0.583332 13.4166 3.45617 13.4166 7Z"
                          stroke="#555555"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{" "}
                    </span>
                    <span className="creator-bottom-value">₹ {premium}</span>
                  </div>
                </div>
              </div>
              <div
                className="d-flex gap-3 mt-3 border-top"
                style={{
                  padding: "12px",
                  marginTop: "auto",
                }}>
                <button
                  className="basket-order-btn"
                  onClick={handleExecuteOrder}>
                  Execute all orders
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BasketMobile;
