import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useFyers } from "../../context/FyersContext";

const OrdersMobile = () => {
  const fyers = useFyers();
  const [orders, setOrders] = useState([]);
  const [filter, setFilter] = useState("all");
  // const access_token = localStorage.getItem("access_token");
  // const [symbol, setSymbol] = useState("");
  // const [qty, setQty] = useState(0);
  // this state is added to keep the track of checkbox
  const [checkboxEnabled, setCheckboxEnabled] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fyers.get_orders();
        console.log("My orders are:", response);
        if (response?.orderBook) {
          setOrders(response.orderBook);
        } else {
          setOrders([]);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, [fyers]);

  const statusValue = (status) => {
    switch (status) {
      case 1:
        return "Canceled";
      case 2:
        return "Traded / Filled";
      case 3:
        return "Not used currently";
      case 4:
        return "Transit";
      case 5:
        return "Rejected";
      case 6:
        return "Pending";
      case 7:
        return "Expired";
      default:
        return "";
    }
  };

  //changing date format. In received object it is different than what is shown in design
  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const time = dateTime.toLocaleTimeString([], {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
    const date = dateTime.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

    return `${time}, ${date}`;
  };

  const filteredOrders = orders.filter((order) => {
    if (filter === "executed" && order.status !== 2) {
      return false; // Skip non-executed orders
    }
    if (filter === "pending" && order.status !== 6) {
      return false; // Skip non-pending orders
    }
    if (filter === "rejected" && order.status !== 5) {
      return false; // Skip non-rejected orders
    }
    return true;
  });

  const isActiveButton = (buttonName) => {
    return filter === buttonName ? "active" : "";
  };

  // const hasPendingOrders = filteredOrders.some((order) => order.status === 6)/

  useEffect(() => {
    const hasPendingOrders = filteredOrders.some((order) => order.status === 6);
    setCheckboxEnabled(hasPendingOrders);
  }, [filteredOrders]);

  const handleCheckboxChange = (order) => {
    // Check if the order is pending before allowing changes
    if (order.status === 6) {
      const isChecked = selectedCheckboxes.includes(order.id);
      if (isChecked) {
        // Remove from selectedCheckboxes
        setSelectedCheckboxes(
          selectedCheckboxes.filter((id) => id !== order.id)
        );
      } else {
        // Add to selectedCheckboxes
        setSelectedCheckboxes([...selectedCheckboxes, order.id]);
      }
    }
  };

  const [showPosCheckButtons, setShowPosCheckButtons] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  return (
    <>
      <Helmet>
        <title>Orders</title>
      </Helmet>
      <div className="orders-page d-flex justify-content-between mt-3">
        <div className="btn-group-1 d-flex">
          <button
            className={`btn-1 all ${isActiveButton("all")}`}
            onClick={() => setFilter("all")}>
            All
          </button>
          <button
            className={`btn-1 executed ${isActiveButton("executed")}`}
            onClick={() => setFilter("executed")}>
            Executed
          </button>
          <button
            className={`btn-1 pending ${isActiveButton("pending")}`}
            onClick={() => setFilter("pending")}>
            Pending
          </button>
          <button
            className={`btn-1 rejected ${isActiveButton("rejected")}`}
            onClick={() => setFilter("rejected")}>
            Rejected
          </button>
        </div>
      </div>
      {filteredOrders.length === 0 ? (
        <h2 className="mt-5 fw-bolder text-center">
          You don't have any {filter !== "all" ? filter : ""} orders!
        </h2>
      ) : (
        <div className="order-mobile-container my-3">
          {filteredOrders.map((order) => (
            <div
              className="d-flex justify-content-between mb-3"
              onClick={() => {
                if (showPosCheckButtons && selectedOrder === order) {
                  setShowPosCheckButtons(false);
                  setSelectedOrder(null);
                } else {
                  setShowPosCheckButtons(true);
                  setSelectedOrder(order);
                }
              }}>
              <div className="order-info-div d-flex flex-column">
                <div className="d-flex gap-3">
                  <div
                    className={
                      order.side === -1 ? "text-danger" : "text-success"
                    }
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                    }}>
                    {order.side === -1 ? "SELL" : "BUY"}
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                    }}>
                    Oty: {`${order.filledQty}/${order.qty}`}
                  </div>
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                  }}>
                  {order.symbol}
                </div>
                <div
                  className="d-flex gap-3"
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                  }}>
                  <div>Market</div>
                  <div>{order.productType}</div>
                </div>
              </div>
              <div className="order-status d-flex flex-column ">
                <div
                  className="mobile-order-status"
                  style={{
                    color:
                      order.status === 2
                        ? "#5CA81D"
                        : order.status === 5
                        ? "#DD6565"
                        : order.status === 6
                        ? "yellow"
                        : "black",
                  }}>
                  {statusValue(order.status)}
                </div>

                <div className="ps-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="16"
                    viewBox="0 0 9 16"
                    fill="none"
                    className="mt-1 ms-4">
                    <path
                      d="M1.36267 1L7.8539 7.96317L1.36267 14.9263"
                      stroke="#14142B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              {showPosCheckButtons && selectedOrder === order && (
                <div className="pos-check-buttons d-flex justify-content-between px-3">
                  <button className="pos-button one-click">Repeat order</button>
                  <button className="pos-button exit-pos">Cancel order</button>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default OrdersMobile;
