import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import { Helmet } from "react-helmet-async";
import OptionChainModal from "../../modals/OptionChainModal";
import shield from "../../images/shield.svg";
import pencil from "../../images/pencil.svg";
import questionmark from "../../images/questionmark.svg";
import PositionsTable from "../../components/PositionsTable";
import AnalyseModal from "../../modals/AnalyseModal";
import axios, { all } from "axios";
import BuySellModal from "../../modals/BuySellModal";
import ABWebSocketContext from "../../context/ABWebsocketContext";
import { getSymbols } from "../../utils/api";
import { useGraphContext } from "../../context/GraphContext";
import { toast } from "react-toastify";
import CustomToastContent from "../../components/CustomToastContent";

const access_token = localStorage.getItem("access_token");
let triggerSubscribe = true;
let connectionEstablised = false;

const PositionsDesktop = () => {
  const [activeButton, setActiveButton] = useState("groupbyunderlying");
  // This useEffect will be executed once when the component mounts
  const [metrics, setMetrics] = useState({
    total_positions: 0,
    open_positions: 0,
  });
  const [nsesubscribeSymbols, setNSESubscribeSymbols] = useState({});
  const [nfosubscribeSymbols, setNFOSubscribeSymbols] = useState({});
  const [allPositions, setAllPositions] = useState([]);
  const [strategyBased, setStrategyBased] = useState([]);
  const [underlyingBased, setUnderlyingBased] = useState([]);
  const [symbols, setSymbols] = useState([]);

  const [netProfit, setNetProfit] = useState(0);
  const [potentialProfit, setPotentialProfit] = useState(0);
  const [potentialLoss, setPotentialLoss] = useState(0);
  const [pendingProfit, setPendingProfit] = useState(0);
  const [achievedPercent, setAchievedPercent] = useState(0);
  const [selectedOption, setSelectedOption] = useState("buy");

  const handleBuyButtonClick = () => {
    setSelectedOption("buy");
  };

  const handleSellButtonClick = () => {
    setSelectedOption("sell");
  };

  let totalMaxProfit = 0;
  let totalMaxLoss = 0;

  const {
    updateSymbolList,
    websocketConnection,
    checkWebsocketConnection,
    subscribeToSymbols,
    unsubscribeToSymbols,
    symbolList,
    symbolData,
  } = useContext(ABWebSocketContext);
  const {
    x_axis,
    y_axis,
    graphData,
    maxProfit,
    maxLoss,
    riskReward,
    breakevenRange,
    strategyDirection,
  } = useGraphContext();

  const minValueOnXAxis = x_axis.length > 0 ? x_axis[0] : 0;
  const maxValueOnXAxis = x_axis.length > 0 ? x_axis[x_axis.length - 1] : 100;

  useEffect(() => {
    const apiEndpoint = `https://dryeadhptf.execute-api.ap-south-1.amazonaws.com/default/optiongeeks_fyers_positions`;
    const body = {
      access_token: access_token,
    };
    const fetchPositions = async () => {
      try {
        const response = await axios.post(apiEndpoint, body);
        if (response.data?.nse_subscribe_symbols) {
          setNSESubscribeSymbols(response.data?.nse_subscribe_symbols);
          setNFOSubscribeSymbols(response.data?.nfo_subscribe_symbols);
          setMetrics(response.data?.metrics);
        }
        setAllPositions(response.data.all_positions);
        const symbols = await getSymbols();
        setSymbols(symbols);
        setUnderlyingBased(response?.data.groupby_underlying);
        setStrategyBased(response?.data.groupby_strategy); //
      } catch (error) {
        console.error("Error fetching positions:", error);
      }
    };

    fetchPositions();
  }, []);

  const handleCallback = async ([rowData]) => {};

  const callBackToUpdateAlloption = (newOptions) => {};

  const handlebreakevens = (
    x_axis,
    greenRange,
    maxProfit,
    maxLoss,
    breakevenRange,
    data,
    type,
    index
  ) => {
    if (type === "symbol") {
      let item = underlyingBased[index];
      item.breakeven = breakevenRange;
      let resp = calculatePointerPosition(greenRange, item);
      item.greenBarWidth = resp?.width;
      item.leftSpace = resp?.leftSpace;
      item.pointerPosition = resp?.pointerPosition;
      item.x_axis = x_axis;
      setUnderlyingBased((prevData) => [...prevData]);
    } else if (type === "strategy") {
      let item = strategyBased[index];
      item.breakeven = breakevenRange;
      let resp = calculatePointerPosition(greenRange, item);
      item.greenBarWidth = resp?.width;
      item.leftSpace = resp?.leftSpace;
      item.pointerPosition = resp?.pointerPosition;
      item.x_axis = x_axis;
      setStrategyBased((prevData) => [...prevData]);
    }

    if (maxLoss === "Unlimited") {
      totalMaxLoss = "Unlimited";
      setPotentialLoss(totalMaxLoss);
    }
    if (maxProfit === "Unlimited") {
      totalMaxProfit = "Unlimited";
      setPotentialProfit(totalMaxProfit);
    }

    if (totalMaxLoss !== "Unlimited") {
      totalMaxLoss = totalMaxLoss + maxLoss;
      setPotentialLoss(totalMaxLoss);
    }
    if (totalMaxProfit !== "Unlimited") {
      totalMaxProfit = totalMaxProfit + maxProfit;
      setPotentialProfit(totalMaxProfit);
    }
  };

  useEffect(() => {
    let pendingProfit = 0;
    let totalPnl = 0;
    allPositions?.map((position, index) => {
      let ltp = symbolData[position?.ab_code]?.ltp;
      if (ltp) {
        let pnl =
          (parseFloat(ltp) - parseFloat(position?.average_prc)) *
          parseFloat(position?.net_quantity);
        totalPnl = totalPnl + pnl;
      }
    });
    setNetProfit(totalPnl.toFixed(2));
    if (potentialProfit !== "Unlimited") {
      pendingProfit = potentialProfit - totalPnl;
      setPendingProfit(pendingProfit.toFixed(2));
      setAchievedPercent(((totalPnl / potentialProfit) * 100).toFixed(2) + "%");
    }
  }, [symbolData, allPositions, potentialProfit]);

  useLayoutEffect(() => {
    connectionEstablised = checkWebsocketConnection();
    if (connectionEstablised) {
      if (triggerSubscribe) {
        if (Object.keys(nsesubscribeSymbols).length !== 0) {
          subscribeToSymbols(nsesubscribeSymbols, "NSE");
        }
        if (Object.keys(nfosubscribeSymbols).length !== 0) {
          subscribeToSymbols(nfosubscribeSymbols, "NFO");
        }
        if (
          Object.keys(nfosubscribeSymbols).length !== 0 &&
          Object.keys(nsesubscribeSymbols).length !== 0
        ) {
          triggerSubscribe = false;
        } else {
          triggerSubscribe = true;
        }
      }
    } else {
      triggerSubscribe = true;
    }
  }, [symbolData, triggerSubscribe, nsesubscribeSymbols, nfosubscribeSymbols]);

  const handleButtonClick = (button) => {
    setActiveButton(button);
    setActiveTable(button);
    // Update the exit button enabled status
    setExitButtonEnabled(isAnyCheckboxChecked);
  };

  const [showButtons, setShowButtons] = useState(false);

  const handleMouseEnter = () => {
    setShowButtons(true);
  };

  const handleMouseLeave = () => {
    setShowButtons(false);
  };

  // For tables in watchlis
  const [showTables, setShowTables] = useState(false);
  const handleToggleTables = () => {
    setShowTables((prevShowTables) => !prevShowTables);
    setShowButtons(true);
  };

  const [selectedUnderlying, setSelectedUnderlying] = useState([]);
  const [selectedExecutedStrategies, setSelectedExecutedStrategies] = useState(
    []
  );

  const [isExitSelectedEnabledUnderlying, setIsExitSelectedEnabledUnderlying] =
    useState(false);
  const [
    isExitSelectedEnabledExecutedStrategies,
    setIsExitSelectedEnabledExecutedStrategies,
  ] = useState(false);

  const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(false);
  const [headerCheckboxChecked, setHeaderCheckboxChecked] = useState(false);

  const [exitButtonEnabled, setExitButtonEnabled] = useState(false);

  const handleCheckboxChange = (table, index) => {
    switch (table) {
      case "groupbyunderlying":
        setSelectedUnderlying((prevSelected) => {
          const underlyingSelected = [...prevSelected];
          underlyingSelected[index] = !underlyingSelected[index];
          setIsExitSelectedEnabledUnderlying(
            underlyingSelected.some((isSelected) => isSelected)
          );
          setHeaderCheckboxChecked(
            underlyingSelected.every((isSelected) => isSelected)
          );
          return underlyingSelected;
        });
        break;
      case "executedstrategies":
        setSelectedExecutedStrategies((prevSelected) => {
          const executedStrategiesSelected = [...prevSelected];
          executedStrategiesSelected[index] =
            !executedStrategiesSelected[index];
          setIsExitSelectedEnabledExecutedStrategies(
            executedStrategiesSelected.some((isSelected) => isSelected)
          );
          setHeaderCheckboxChecked(
            executedStrategiesSelected.every((isSelected) => isSelected)
          );
          return executedStrategiesSelected;
        });
        break;
      default:
        break;
    }
  };

  const handleHeaderCheckboxChange = (table) => {
    setHeaderCheckboxChecked((prevChecked) => !prevChecked);

    const allChecked = new Array(
      table === "groupbyunderlying"
        ? underlyingBased.length
        : strategyBased.length
    ).fill(!headerCheckboxChecked);

    if (table === "groupbyunderlying") {
      setSelectedUnderlying(allChecked);
    } else if (table === "executedstrategies") {
      setSelectedExecutedStrategies(allChecked);
    }
  };

  const handleExitSelected = (table) => {
    switch (table) {
      case "groupbyunderlying":
        setSelectedUnderlying([]);
        setIsExitSelectedEnabledUnderlying(false);
        break;
      case "executedstrategies":
        setSelectedExecutedStrategies([]);
        setIsExitSelectedEnabledExecutedStrategies(false);
        break;
      default:
        break;
    }
  };

  const handleOKClick = () => {
    exitAllPositions();
    const overlay = document.querySelector(".overlay");
    overlay.classList.remove("active");
  };

  const handleCloseClick = () => {
    const overlay = document.querySelector(".overlay");
    overlay.classList.remove("active");
    toast.dismiss();
  };
  const showToast = () => {
    const overlay = document.querySelector(".overlay");
    overlay.classList.add("active");

    toast(
      <div className="d-flex align-items-center justify-content-between">
        <div>Sure, you want to exit all the open postions?</div>
        <div className="d-flex justify-content-end align-items-center gap-2">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              onClick={handleCloseClick}>
              <path
                d="M1 1.00002L13.7742 13.7742M1 13.7742L13.7742 1"
                stroke="#FF3908"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div
            className="confirm-popup-ok d-flex justify-content-center align-items-center"
            onClick={handleOKClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="14"
              viewBox="0 0 19 14"
              fill="none">
              <path
                d="M1 6.65685L6.65685 12.3137L17.9706 1"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </div>
      </div>,
      {
        position: "bottom-center",
        closeButton: false,
        icon: false,
        className: "confirm-popup-toast",
        hideProgressBar: true,
        draggable: true,
        autoClose: false,
      }
    );
  };

  const exitAllPositions = () => {
    setSelectedUnderlying([]);
    setSelectedExecutedStrategies([]);
    setIsExitSelectedEnabledUnderlying(true);
    setIsExitSelectedEnabledExecutedStrategies(true);
  };
  useEffect(() => {
    setIsExitSelectedEnabledUnderlying(
      selectedUnderlying.some((isSelected) => isSelected)
    );
    setIsExitSelectedEnabledExecutedStrategies(
      selectedExecutedStrategies.some((isSelected) => isSelected)
    );
  }, [selectedUnderlying, selectedExecutedStrategies]);

  const [activeTable, setActiveTable] = useState("allpositions");

  function formatNumberWithFontWeight(number) {
    if (number === "Unlimited") {
      return <span style={{ fontWeight: 500 }}>Unlimited</span>;
    }
    const formattedValue = parseFloat(number).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const parts = formattedValue.split(".");
    const formattedFraction = (
      <span style={{ fontWeight: 400 }}>{parts[1]}</span>
    );

    return (
      <span>
        {parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}.{""}
        {formattedFraction}
      </span>
    );
  }

  const calculateLtpPointerPosition = (x_axis, ltp) => {
    try {
      const range =
        parseFloat(x_axis[x_axis?.length - 1]) - parseFloat(x_axis[0]);
      let ltpFromLeft = ltp - x_axis[0];
      let pointerPosition = (ltpFromLeft / range) * 100;
      return pointerPosition;
    } catch (e) {
      return 0;
    }
  };

  const calculatePointerPosition = (greenRange, item) => {
    const range = parseFloat(x_axis[x_axis.length - 1]) - parseFloat(x_axis[0]);
    let barPosition;
    let greenBarWidth;
    if (greenRange.length == 2) {
      greenBarWidth = greenRange[1] - greenRange[0];
      barPosition = greenRange[0];
    }
    const thumbWidthPercentage = (greenBarWidth / range) * 100;
    barPosition = (greenRange[0] / x_axis[0]) * 100;
    let leftDifference = greenRange[0] - x_axis[0];
    let leftSpace = (leftDifference / range) * 100;

    let ltp = symbolData[item?.ab_code]?.ltp;
    let ltpFromLeft = ltp - x_axis[0];
    let pointerPosition = (ltpFromLeft / range) * 100;

    return {
      width: thumbWidthPercentage,
      leftSpace: leftSpace,
      pointerPosition: pointerPosition,
    };
  };

  return (
    <>
      <Helmet>
        <title>Positions</title>
      </Helmet>
      <div className="overlay"></div>
      <div className="pos-left d-flex-flex-column mt-3 mb-5">
        <div className="position-analytic px-3 py-3">Position Analytics</div>
        <div className="pos-strategy-menu flex-column d-flex justify-content-start align-items-start px-3 gap-4">
          <div className="d-flex justify-content-start gap-3">
            <div className="d-flex flex-column">
              <span className="creator-bottom-title">Overall P&L</span>
              <span
                className="pos-strategy-menu-value"
                style={{ color: netProfit > 0 ? "#5CA81D" : "#DD6565" }}>
                {formatNumberWithFontWeight(netProfit)}
              </span>
            </div>

            <div className="d-flex flex-column">
              <span className="creator-bottom-title">Achieved P&L</span>
              <span className="pos-strategy-menu-value">
                {String(achievedPercent).split(".")[0]}.
                <span style={{ fontWeight: 400 }}>
                  {String(achievedPercent).split(".")[1]}
                </span>
              </span>
            </div>
            <div className="d-flex flex-column">
              <span className="creator-bottom-title">Margin available</span>
              <span className="pos-strategy-menu-value">-</span>
            </div>
          </div>
          <div className="d-flex justify-content-start gap-3">
            <div className="d-flex flex-column">
              <span className="creator-bottom-title">
                Potential profit <img src={questionmark} alt="" />
              </span>
              <span className="pos-strategy-menu-value">
                {formatNumberWithFontWeight(pendingProfit)}
              </span>
            </div>

            <div className="d-flex flex-column">
              <span className="creator-bottom-title">
                Potential loss <img src={questionmark} alt="" />
              </span>
              <span className="pos-strategy-menu-value">
                {potentialLoss === "Unlimited"
                  ? potentialLoss
                  : String(potentialLoss).split(".")[0]}
                {potentialLoss === "Unlimited" ? null : "."}
                <span style={{ fontWeight: 400 }}>
                  {potentialLoss === "Unlimited"
                    ? null
                    : String(potentialLoss).split(".")[1]}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="pos-right d-flex flex-column mt-3 mb-5 gap-3">
        <div className="graph-btns d-flex gap-2">
          <button
            className={`filter-btns ${
              activeButton === "groupbyunderlying" ? "active" : ""
            }`}
            onClick={() => handleButtonClick("groupbyunderlying")}>
            Group by underlying
          </button>
          <button
            className={`filter-btns ${
              activeButton === "executedstrategies" ? "active" : ""
            }`}
            onClick={() => handleButtonClick("executedstrategies")}>
            Executed strategies
          </button>

          {activeButton === "groupbyunderlying" && (
            <button className="exit enabled" onClick={() => showToast()}>
              Exit all positions
            </button>
          )}

          {activeButton === "executedstrategies" && (
            <button className="exit enabled" onClick={() => showToast()}>
              Exit all positions
            </button>
          )}
        </div>

        {/* Group by underlying table */}
        {activeButton == "groupbyunderlying" &&
          underlyingBased &&
          underlyingBased.map((data, index) => (
            <div className="group-table d-flex flex-column gap-3">
              <div className="group-container">
                <div className="border-0 d-flex flex-column justify-content-start p-3 gap-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="symbol-info d-flex flex-column">
                      <div className="symbol-name d-flex justify-content-between">
                        <div>{data?.scrip}</div>
                      </div>
                      <div>
                        <span className="lp">
                          {symbolData[data?.ab_code]?.ltp}
                        </span>
                        <span
                          className="ch-chp me-2"
                          style={{
                            color:
                              symbolData[data?.ab_code]?.change > 0
                                ? "#5CA81D" // Green color for positive change
                                : "#DD6565", // Red color for negative change
                          }}>
                          {symbolData[data?.ab_code]?.change > 0 ? "+" : ""}
                          {symbolData[data?.ab_code]?.change}(
                          {symbolData[data?.ab_code]?.change_per}%)
                        </span>
                      </div>
                    </div>

                    <div className="d-flex gap-3 align-items-center">
                      <button
                        className="analyse-op enabled"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdropAnalyseModal">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="#383FEC">
                          <g clip-path="url(#clip0_454_199)">
                            <path
                              d="M8.78566 6.78402C8.78566 6.45052 9.05776 6.17977 9.39293 6.17977C9.7279 6.17977 10 6.45052 10 6.78402V8.5008C10 8.91289 9.83045 9.28842 9.55776 9.55996C9.28507 9.83129 8.90766 10 8.49332 10H1.50668C1.09234 10 0.714931 9.83129 0.44224 9.55996C0.169548 9.28842 0 8.91289 0 8.5008V1.4992C0 1.08691 0.169548 0.711381 0.44224 0.440044C0.714931 0.168706 1.09234 0 1.50668 0H3.21552C3.55069 0 3.82259 0.270751 3.82259 0.604058C3.82259 0.937561 3.55069 1.20831 3.21552 1.20831H1.50668C1.42672 1.20831 1.35305 1.24135 1.3002 1.29374C1.24754 1.34633 1.21434 1.41963 1.21434 1.4992V8.5008C1.21434 8.58017 1.24754 8.65367 1.3002 8.70606C1.35305 8.75845 1.42672 8.79169 1.50668 8.79169H8.49332C8.57328 8.79169 8.64695 8.75845 8.6998 8.70606C8.75246 8.65367 8.78566 8.58017 8.78566 8.5008V6.78402ZM8.78566 2.02272L6.05403 4.77538C5.82004 5.01232 5.43615 5.01544 5.19823 4.78262C4.96031 4.54979 4.95697 4.16781 5.19096 3.93107L7.89332 1.20831H6.08448C5.74931 1.20831 5.47741 0.937561 5.47741 0.604058C5.47741 0.270751 5.74931 0 6.08448 0H9.39293C9.7279 0 10 0.270751 10 0.604058V3.83039C10 4.1639 9.7279 4.43445 9.39293 4.43445C9.05776 4.43445 8.78566 4.1639 8.78566 3.83039V2.02272Z"
                              fill="#383FEC"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_454_199">
                              <rect width="10" height="10" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>{" "}
                        Analyse
                      </button>

                      <AnalyseModal
                        parentCallback2={handlebreakevens}
                        selectedData={data.positions}
                        selectedSymbol={
                          symbols?.filter(
                            (option) => option.scrip === data.scrip
                          )[0]
                        }
                        type="symbol"
                        index={index}
                      />
                      {activeButton === "groupbyunderlying" && (
                        <button
                          className={`pos-exit-analyse ${
                            isExitSelectedEnabledUnderlying
                              ? "enabled"
                              : "disabled"
                          }`}
                          onClick={() =>
                            handleExitSelected("groupbyunderlying")
                          }
                          disabled={!isExitSelectedEnabledUnderlying}>
                          Exit
                        </button>
                      )}
                      {/* <button
                        className="analyse-op"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdropOptionChainModal">
                        Option chain
                      </button> */}
                      <OptionChainModal
                        parentCallback={handleCallback}
                        parentCallback2={callBackToUpdateAlloption}
                        selectedSymbol={
                          symbols?.filter(
                            (option) => option.scrip === data.scrip
                          )[0]
                        }
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-start gap-3">
                    <div className="d-flex flex-column">
                      <span className="creator-bottom-title">Net P&L</span>
                      <span
                        className="pos-strategy-menu-value"
                        style={{
                          color: netProfit > 0 ? "#5CA81D" : "#DD6565",
                        }}>
                        {formatNumberWithFontWeight(netProfit)}
                      </span>
                    </div>
                    <div className="d-flex flex-column">
                      <span className="creator-bottom-title">Max profit </span>
                      <span className="pos-strategy-menu-value">
                        {formatNumberWithFontWeight(maxProfit)}
                      </span>
                    </div>
                    <div className="d-flex flex-column">
                      <span className="creator-bottom-title">Max loss</span>
                      <span className="pos-strategy-menu-value">
                        {formatNumberWithFontWeight(maxLoss)}
                      </span>
                    </div>
                    <div className="d-flex flex-column">
                      <span className="creator-bottom-title">Breakeven </span>
                      <span className="pos-strategy-menu-value">
                        {data?.breakeven}
                      </span>
                    </div>
                    <div className="d-flex flex-column ms-auto">
                      <div className="custom-range-container">
                        <div>
                          <div className="d-flex justify-content-start mb-3 creator-bottom-title">
                            P&L range by underlying
                          </div>
                          <div className="custom-range-container">
                            <div>
                              <div
                                className="custom-range"
                                style={{
                                  "--slider-color": "#5CA81D",
                                  background: "#DD6565",
                                }}>
                                <div
                                  className="slider"
                                  style={{
                                    width: `${data?.greenBarWidth}%`,
                                    left: `${data?.leftSpace}%`,
                                    backgroundColor: "var(--slider-color)",
                                    top: "50%",
                                  }}></div>
                              </div>
                              <div
                                style={{
                                  left: `calc(${calculateLtpPointerPosition(
                                    data.x_axis,
                                    symbolData[data?.ab_code]?.ltp
                                  )}% - 1.8%)`,
                                  position: "absolute",
                                  top: "2px",
                                }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="11"
                                  height="7"
                                  viewBox="0 0 11 7"
                                  fill="none">
                                  <path
                                    d="M4.40608 0.401811C4.90098 -0.133937 5.74756 -0.133937 6.24246 0.401811L10.3143 4.80965C11.0538 5.61025 10.486 6.90784 9.39606 6.90784L1.25248 6.90784C0.162566 6.90784 -0.405272 5.61025 0.334291 4.80965L4.40608 0.401811Z"
                                    fill="#1E2028"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <table className="position-table w-100">
                  <thead>
                    <tr className="text-center position-table-header ">
                      <th scope="col">
                        {/* <input
                          type="checkbox"
                          checked={headerCheckboxChecked}
                          onChange={() =>
                            handleHeaderCheckboxChange("groupbyunderlying")
                          }
                        /> */}
                      </th>
                      <th scope="col" className="text-start">
                        Instruments
                      </th>
                      <th>B/S</th>
                      <th>Expiry</th>
                      <th scope="col">Product</th>
                      <th scope="col">Quanity</th>
                      <th scope="col">Avg price</th>
                      <th scope="col">LTP</th>
                      <th scope="col"> P&L</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.positions &&
                      data.positions.map((obj, index) => (
                        <tr className="position-table-row text-center">
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedUnderlying[index] || false}
                              onChange={() =>
                                handleCheckboxChange("groupbyunderlying", index)
                              }
                            />
                          </td>
                          <td className="table-data text-start">
                            {obj.instrument_name}
                          </td>
                          <td
                            className={`table-data buy-sell ${
                              obj.isBuy ? "pos-buy" : "pos-sell"
                            }`}>
                            {obj.isBuy ? "BUY" : "SELL"}
                          </td>

                          <td className="table-data">{obj.expiry_date}</td>
                          <td className="d-flex align-items-center justify-content-center my-1">
                            <div
                              className={`product-type text-center d-flex align-items-center justify-content-center ${obj.product_type}`}>
                              {obj.product_type}
                            </div>
                          </td>
                          <td
                            className="table-data"
                            style={{
                              color:
                                parseFloat(obj.net_quantity) > 0
                                  ? "#5CA81D"
                                  : "#DD6565",
                            }}>
                            {obj.net_quantity}
                          </td>
                          <td className="table-data">
                            {String(obj.average_prc).split(".")[0]}.
                            <span style={{ fontWeight: 400 }}>
                              {String(obj.average_prc).split(".")[1]}
                            </span>
                          </td>
                          <td className="table-data">
                            {String(symbolData[obj.ab_code]?.ltp).split(".")[0]}
                            .
                            <span style={{ fontWeight: 400 }}>
                              {
                                String(symbolData[obj.ab_code]?.ltp).split(
                                  "."
                                )[1]
                              }
                            </span>
                          </td>
                          <td
                            className="table-data"
                            style={{
                              color:
                                parseFloat(
                                  (symbolData[obj.ab_code]?.ltp -
                                    obj.average_prc) *
                                    obj.net_quantity
                                ) > 0
                                  ? "#5CA81D"
                                  : "#DD6565",
                            }}>
                            {formatNumberWithFontWeight(
                              (symbolData[obj.ab_code]?.ltp - obj.average_prc) *
                                obj.net_quantity
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}

        {/* Executed strategy table */}
        {activeButton === "executedstrategies" &&
          strategyBased &&
          strategyBased.map((data, index) => (
            <div className="executed-table group-table d-flex flex-column gap-3">
              <div className="group-container">
                <div className="border-0 d-flex flex-column justify-content-start p-3 gap-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="symbol-info d-flex flex-column">
                      <div className="symbol-name d-flex justify-content-between">
                        <div>{data?.scrip}</div>
                      </div>
                      <div>
                        <span className="lp">
                          {symbolData[data?.ab_code]?.ltp}
                        </span>
                        <span
                          className="ch-chp me-2"
                          style={{
                            color:
                              symbolData[data?.ab_code]?.change > 0
                                ? "#5CA81D" // Green color for positive change
                                : "#DD6565", // Red color for negative change
                          }}>
                          {symbolData[data?.ab_code]?.change > 0 ? "+" : ""}
                          {symbolData[data?.ab_code]?.change}(
                          {symbolData[data?.ab_code]?.change_per}%)
                        </span>
                      </div>
                    </div>

                    <div className="d-flex gap-3 align-items-center">
                      <div className="strategy-name">
                        <svg
                          style={{ marginRight: "6px" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none">
                          <circle cx="5" cy="5" r="5" fill="#DD6565" />
                        </svg>

                        {data.strategy}
                      </div>
                      <button
                        className="analyse-op enabled"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdropAnalyseModal">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="#383FEC">
                          <g clip-path="url(#clip0_454_199)">
                            <path
                              d="M8.78566 6.78402C8.78566 6.45052 9.05776 6.17977 9.39293 6.17977C9.7279 6.17977 10 6.45052 10 6.78402V8.5008C10 8.91289 9.83045 9.28842 9.55776 9.55996C9.28507 9.83129 8.90766 10 8.49332 10H1.50668C1.09234 10 0.714931 9.83129 0.44224 9.55996C0.169548 9.28842 0 8.91289 0 8.5008V1.4992C0 1.08691 0.169548 0.711381 0.44224 0.440044C0.714931 0.168706 1.09234 0 1.50668 0H3.21552C3.55069 0 3.82259 0.270751 3.82259 0.604058C3.82259 0.937561 3.55069 1.20831 3.21552 1.20831H1.50668C1.42672 1.20831 1.35305 1.24135 1.3002 1.29374C1.24754 1.34633 1.21434 1.41963 1.21434 1.4992V8.5008C1.21434 8.58017 1.24754 8.65367 1.3002 8.70606C1.35305 8.75845 1.42672 8.79169 1.50668 8.79169H8.49332C8.57328 8.79169 8.64695 8.75845 8.6998 8.70606C8.75246 8.65367 8.78566 8.58017 8.78566 8.5008V6.78402ZM8.78566 2.02272L6.05403 4.77538C5.82004 5.01232 5.43615 5.01544 5.19823 4.78262C4.96031 4.54979 4.95697 4.16781 5.19096 3.93107L7.89332 1.20831H6.08448C5.74931 1.20831 5.47741 0.937561 5.47741 0.604058C5.47741 0.270751 5.74931 0 6.08448 0H9.39293C9.7279 0 10 0.270751 10 0.604058V3.83039C10 4.1639 9.7279 4.43445 9.39293 4.43445C9.05776 4.43445 8.78566 4.1639 8.78566 3.83039V2.02272Z"
                              fill="#383FEC"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_454_199">
                              <rect width="10" height="10" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>{" "}
                        Analyse
                      </button>
                      <AnalyseModal
                        parentCallback2={handlebreakevens}
                        selectedData={data.positions}
                        selectedSymbol={
                          symbols?.filter(
                            (option) => option.scrip === data.scrip
                          )[0]
                        }
                        type="strategy"
                        index={index}
                      />
                      {activeButton === "executedstrategies" && (
                        <button
                          className={`pos-exit-analyse ${
                            isExitSelectedEnabledExecutedStrategies
                              ? "enabled"
                              : "disabled"
                          }`}
                          onClick={() =>
                            handleExitSelected("executedstrategies")
                          }
                          disabled={!isExitSelectedEnabledExecutedStrategies}>
                          Exit
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-start gap-3">
                    <div className="d-flex flex-column">
                      <span className="creator-bottom-title">Net P&L</span>
                      <span
                        className="pos-strategy-menu-value"
                        style={{
                          color: netProfit > 0 ? "#5CA81D" : "#DD6565",
                        }}>
                        {formatNumberWithFontWeight(netProfit)}
                      </span>
                    </div>
                    <div className="d-flex flex-column">
                      <span className="creator-bottom-title">Max profit </span>
                      <span className="pos-strategy-menu-value">
                        {formatNumberWithFontWeight(maxProfit)}
                      </span>
                    </div>
                    <div className="d-flex flex-column">
                      <span className="creator-bottom-title">Max loss</span>
                      <span className="pos-strategy-menu-value">
                        {formatNumberWithFontWeight(maxLoss)}
                      </span>
                    </div>
                    <div className="d-flex flex-column">
                      <span className="creator-bottom-title">Breakeven </span>
                      <span className="pos-strategy-menu-value">
                        {data?.breakeven}
                      </span>
                    </div>
                    <div className="d-flex flex-column ms-auto">
                      <div className="custom-range-container">
                        <div>
                          <div className="d-flex justify-content-start mb-3 creator-bottom-title">
                            P&L range by underlying
                          </div>
                          <div className="custom-range-container">
                            <div>
                              <div
                                className="custom-range"
                                style={{
                                  "--slider-color": "#5CA81D",
                                  background: "#DD6565",
                                }}>
                                <div
                                  className="slider"
                                  style={{
                                    width: `${data?.greenBarWidth}%`,
                                    left: `${data?.leftSpace}%`,
                                    backgroundColor: "var(--slider-color)",
                                    top: "50%",
                                  }}></div>
                              </div>
                              <div
                                style={{
                                  left: `calc(${calculateLtpPointerPosition(
                                    data.x_axis,
                                    symbolData[data?.ab_code]?.ltp
                                  )}% - 1.8%)`,
                                  position: "absolute",
                                  top: "2px",
                                }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="11"
                                  height="7"
                                  viewBox="0 0 11 7"
                                  fill="none">
                                  <path
                                    d="M4.40608 0.401811C4.90098 -0.133937 5.74756 -0.133937 6.24246 0.401811L10.3143 4.80965C11.0538 5.61025 10.486 6.90784 9.39606 6.90784L1.25248 6.90784C0.162566 6.90784 -0.405272 5.61025 0.334291 4.80965L4.40608 0.401811Z"
                                    fill="#1E2028"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <table className="position-table w-100">
                  <thead>
                    <tr className="text-center position-table-header ">
                      <th scope="col">{/* <input type="checkbox" /> */}</th>
                      <th scope="col" className="text-start">
                        Instruments
                      </th>
                      <th>B/S</th>
                      <th>Expiry</th>
                      <th scope="col">Product</th>
                      <th scope="col">Quanity</th>
                      <th scope="col">Avg price</th>
                      <th scope="col">LTP</th>
                      <th scope="col"> P&L</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.positions &&
                      data.positions.map((obj, index) => (
                        <tr className="position-table-row text-center">
                          <td>
                            <input
                              type="checkbox"
                              name=""
                              id=""
                              checked={
                                selectedExecutedStrategies[index] || false
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "executedstrategies",
                                  index
                                )
                              }
                            />
                          </td>
                          <td className="table-data text-start">
                            {obj.instrument_name}
                          </td>
                          <td
                            className={`table-data buy-sell ${
                              obj.isBuy ? "pos-buy" : "pos-sell"
                            }`}>
                            {obj.isBuy ? "BUY" : "SELL"}
                          </td>

                          <td className="table-data">{obj.expiry_date}</td>
                          <td className="d-flex align-items-center justify-content-center my-1">
                            <div
                              className={`product-type text-center d-flex align-items-center justify-content-center ${obj.product_type}`}>
                              {obj.product_type}
                            </div>
                          </td>
                          <td
                            className="table-data"
                            style={{
                              color:
                                parseFloat(obj.net_quantity) > 0
                                  ? "#5CA81D"
                                  : "#DD6565",
                            }}>
                            {obj.net_quantity}
                          </td>
                          <td className="table-data">
                            {String(obj.average_prc).split(".")[0]}.
                            <span style={{ fontWeight: 400 }}>
                              {String(obj.average_prc).split(".")[1]}
                            </span>
                          </td>
                          <td className="table-data">
                            {String(symbolData[obj.ab_code]?.ltp).split(".")[0]}
                            .
                            <span style={{ fontWeight: 400 }}>
                              {
                                String(symbolData[obj.ab_code]?.ltp).split(
                                  "."
                                )[1]
                              }
                            </span>
                          </td>
                          <td
                            className="table-data"
                            style={{
                              color:
                                parseFloat(
                                  (symbolData[obj.ab_code]?.ltp -
                                    obj.average_prc) *
                                    obj.net_quantity
                                ) > 0
                                  ? "#5CA81D"
                                  : "#DD6565",
                            }}>
                            {formatNumberWithFontWeight(
                              (symbolData[obj.ab_code]?.ltp - obj.average_prc) *
                                obj.net_quantity
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
      </div>
      <BuySellModal
        activeBuySellOption={selectedOption}
        setActiveBuySellOption={setSelectedOption}
      />
    </>
  );
};

export default PositionsDesktop;
