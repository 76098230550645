import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import ABWebSocketContext from "../context/ABWebsocketContext";
import axios from "axios";
import { getSymbols } from "../utils/api";

const access_token = localStorage.getItem("access_token");
let triggerSubscribe = true;
let connectionEstablised = false;

const Watchlist = () => {
  const [activeButton, setActiveButton] = useState("groupbyunderlying");
  // This useEffect will be executed once when the component mounts
  const [metrics, setMetrics] = useState({
    total_positions: 0,
    open_positions: 0,
  });
  const [nsesubscribeSymbols, setNSESubscribeSymbols] = useState({});
  const [nfosubscribeSymbols, setNFOSubscribeSymbols] = useState({});
  const [allPositions, setAllPositions] = useState([]);
  const [strategyBased, setStrategyBased] = useState([]);
  const [underlyingBased, setUnderlyingBased] = useState([]);
  const [symbols, setSymbols] = useState([]);

  const [netProfit, setNetProfit] = useState(0);
  const [potentialProfit, setPotentialProfit] = useState(0);
  const [potentialLoss, setPotentialLoss] = useState(0);
  const [pendingProfit, setPendingProfit] = useState(0);
  const [achievedPercent, setAchievedPercent] = useState(0);
  const [selectedOption, setSelectedOption] = useState("buy");

  const handleBuyButtonClick = () => {
    setSelectedOption("buy");
  };

  const handleSellButtonClick = () => {
    setSelectedOption("sell");
  };

  let totalMaxProfit = 0;
  let totalMaxLoss = 0;

  const {
    updateSymbolList,
    websocketConnection,
    checkWebsocketConnection,
    subscribeToSymbols,
    unsubscribeToSymbols,
    symbolList,
    symbolData,
  } = useContext(ABWebSocketContext);

  useEffect(() => {
    const apiEndpoint = `https://dryeadhptf.execute-api.ap-south-1.amazonaws.com/default/optiongeeks_fyers_positions`;
    const body = {
      access_token: access_token,
    };
    const fetchPositions = async () => {
      try {
        const response = await axios.post(apiEndpoint, body);
        if (response.data?.nse_subscribe_symbols) {
          setNSESubscribeSymbols(response.data?.nse_subscribe_symbols);
          setNFOSubscribeSymbols(response.data?.nfo_subscribe_symbols);
          setMetrics(response.data?.metrics);
        }
        setAllPositions(response.data.all_positions);
        const symbols = await getSymbols();
        setSymbols(symbols);
        setStrategyBased(response?.data.groupby_strategy);
        setUnderlyingBased(response?.data.groupby_underlying);
      } catch (error) {
        console.error("Error fetching positions:", error);
      }
    };

    fetchPositions();
  }, []);

  const handleCallback = async ([rowData]) => {};

  const callBackToUpdateAlloption = (newOptions) => {};

  const handlebreakevens = (
    maxProfit,
    maxLoss,
    breakevenRange,
    data,
    type,
    index
  ) => {
    if (type === "symbol") {
      let item = underlyingBased[index];
      item.breakeven = breakevenRange;
      setUnderlyingBased((prevData) => [...prevData]);
    } else if (type === "strategy") {
      let item = strategyBased[index];
      item.breakeven = breakevenRange;
      setStrategyBased((prevData) => [...prevData]);
    }
    // debugger;
    if (maxLoss === "Unlimited") {
      totalMaxLoss = "Unlimited";
      setPotentialLoss(totalMaxLoss);
    }
    if (maxProfit === "Unlimited") {
      totalMaxProfit = "Unlimited";
      setPotentialProfit(totalMaxProfit);
    }

    if (totalMaxLoss !== "Unlimited") {
      totalMaxLoss = totalMaxLoss + maxLoss;
      setPotentialLoss(totalMaxLoss);
    }
    if (totalMaxProfit !== "Unlimited") {
      totalMaxProfit = totalMaxProfit + maxProfit;
      setPotentialProfit(totalMaxProfit);
    }
  };

  useEffect(() => {
    let pendingProfit = 0;
    let totalPnl = 0;
    allPositions?.map((position, index) => {
      let ltp = symbolData[position?.ab_code]?.ltp;
      if (ltp) {
        let pnl =
          (parseFloat(ltp) - parseFloat(position?.average_prc)) *
          parseFloat(position?.net_quantity);
        totalPnl = totalPnl + pnl;
      }
    });
    setNetProfit(totalPnl.toFixed(2));
    if (potentialProfit !== "Unlimited") {
      pendingProfit = potentialProfit - totalPnl;
      setPendingProfit(pendingProfit.toFixed(2));
      setAchievedPercent(((totalPnl / potentialProfit) * 100).toFixed(2) + "%");
    }
  }, [symbolData, allPositions, potentialProfit]);

  useLayoutEffect(() => {
    connectionEstablised = checkWebsocketConnection();
    if (connectionEstablised) {
      if (triggerSubscribe) {
        if (Object.keys(nsesubscribeSymbols).length !== 0) {
          subscribeToSymbols(nsesubscribeSymbols, "NSE");
        }
        if (Object.keys(nfosubscribeSymbols).length !== 0) {
          subscribeToSymbols(nfosubscribeSymbols, "NFO");
        }
        if (
          Object.keys(nfosubscribeSymbols).length !== 0 &&
          Object.keys(nsesubscribeSymbols).length !== 0
        ) {
          triggerSubscribe = false;
        } else {
          triggerSubscribe = true;
        }
      }
    } else {
      triggerSubscribe = true;
    }
  }, [symbolData, triggerSubscribe, nsesubscribeSymbols, nfosubscribeSymbols]);

  const handleButtonClick = (button) => {
    setActiveButton(button);
    setActiveTable(button);
    // Update the exit button enabled status
    setExitButtonEnabled(isAnyCheckboxChecked);
  };

  const [showButtons, setShowButtons] = useState(false);

  const handleMouseEnter = () => {
    setShowButtons(true);
  };

  const handleMouseLeave = () => {
    setShowButtons(false);
  };

  // For tables in watchlis
  const [showTables, setShowTables] = useState(false);
  const handleToggleTables = () => {
    setShowTables((prevShowTables) => !prevShowTables);
    setShowButtons(true);
  };

  const [selectedAllPositions, setSelectedAllPositions] = useState([]);
  const [selectedUnderlying, setSelectedUnderlying] = useState([]);
  const [selectedExecutedStrategies, setSelectedExecutedStrategies] = useState(
    []
  );

  const [
    isExitSelectedEnabledAllPositions,
    setIsExitSelectedEnabledAllPositions,
  ] = useState(false);
  const [isExitSelectedEnabledUnderlying, setIsExitSelectedEnabledUnderlying] =
    useState(false);
  const [
    isExitSelectedEnabledExecutedStrategies,
    setIsExitSelectedEnabledExecutedStrategies,
  ] = useState(false);

  const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(false);

  const [exitButtonEnabled, setExitButtonEnabled] = useState(false);

  const handleCheckboxChange = (table, index) => {
    switch (table) {
      case "allpositions":
        const allPositionsSelected = [...selectedAllPositions];
        allPositionsSelected[index] = !allPositionsSelected[index];
        setSelectedAllPositions(allPositionsSelected);
        setIsExitSelectedEnabledAllPositions(
          allPositionsSelected.some((isSelected) => isSelected)
        );
        break;
      case "groupbyunderlying":
        const underlyingSelected = [...selectedUnderlying];
        underlyingSelected[index] = !underlyingSelected[index];
        setSelectedUnderlying(underlyingSelected);
        setIsExitSelectedEnabledUnderlying(
          underlyingSelected.some((isSelected) => isSelected)
        );
        break;
      case "executedstrategies":
        const executedStrategiesSelected = [...selectedExecutedStrategies];
        executedStrategiesSelected[index] = !executedStrategiesSelected[index];
        setSelectedExecutedStrategies(executedStrategiesSelected);
        setIsExitSelectedEnabledExecutedStrategies(
          executedStrategiesSelected.some((isSelected) => isSelected)
        );
        break;
      default:
        break;
    }
  };

  const handleExitSelected = (table) => {
    switch (table) {
      case "allpositions":
        setSelectedAllPositions([]);
        setIsExitSelectedEnabledAllPositions(false);
        break;
      case "groupbyunderlying":
        setSelectedUnderlying([]);
        setIsExitSelectedEnabledUnderlying(false);
        break;
      case "executedstrategies":
        setSelectedExecutedStrategies([]);
        setIsExitSelectedEnabledExecutedStrategies(false);
        break;
      default:
        break;
    }
  };

  const [activeTable, setActiveTable] = useState("allpositions");

  function formatNumberWithFontWeight(number) {
    const formattedValue = parseFloat(number).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const parts = formattedValue.split(".");
    const formattedFraction = (
      <span style={{ fontWeight: 400 }}>{parts[1]}</span>
    );

    return (
      <span>
        {parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}.{""}
        {formattedFraction}
      </span>
    );
  }
  return (
    <div className="pos-left mb-5">
      <div className="watchlist watchlist-container mt-3">
        <div className="watchlist-title px-3 pt-3">
          Watchlist{" "}
          {/* <span className="creator-bottom-title">Internal testing</span> */}
        </div>

        <div className="px-3">
          <input
            type="text"
            placeholder=" Search and add F&O instruments into watchlist"
            className="watchlist-search d-flex justify-content-center align-items-center mt-2 mb-5 w-100"></input>
        </div>
      </div>
    </div>
  );
};

export default Watchlist;
